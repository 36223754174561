<template>
	<div class="dark-background menu-class">
		<a-menu
			v-model:openKeys="openKeys"
			v-model:selectedKeys="selectedKeys"
			mode="inline"
			theme="dark"
		>
			<section>
				<img
					style="
						width: 100%;
						max-height: 40px;
						object-fit: contain;
						margin: 10px 0;
					"
					:src="logo"
				/>
			</section>

			<div
				v-for="({ key, name, path, icon, collapse, newtab }, i) in filter_menus(
					links
				)"
				:key="key"
			>
				<a-menu-divider></a-menu-divider>
				<a-sub-menu v-if="filteredCollapse(collapse).length" :key="`sub${i}`">
					<template #icon>
						<span
							style="font-size: 20px; margin: 0"
							class="material-symbols-outlined"
							>{{ icon }}</span
						>
					</template>
					<template #title>{{ name }}</template>
					<a-menu-item v-for="(sub, c) in filteredCollapse(collapse)" :key="`sub${i}${c}`">
						<router-link :to="sub.path">{{ sub.name }}</router-link>
					</a-menu-item>
				</a-sub-menu>

				<a-menu-item v-else :key="i">
					<template #icon>
						<span
							style="font-size: 20px; margin: 0"
							class="material-symbols-outlined"
							>{{ icon }}</span
						>
					</template>
					<a v-if="newtab" :href="path" target="_blank">{{ name }}</a>
					<router-link v-else :to="path">{{ name }}</router-link>
				</a-menu-item>
			</div>
			<a-modal v-model:visible="visible" title="เปลี่ยนรหัสผ่านใหม่" @ok="handleOk" :maskClosable="false" :closable="false" footer>
				<a-form :model="form" layout="vertical" @finish="onFinish">
							<a-form-item label="รหัสผ่านใหม่" name="password" :rules="[{ required: true, message: 'โปรดกรอกรหัสผ่านใหม่!' }]">
									<a-input-password v-model:value="form.password" type="password"></a-input-password>
							</a-form-item>
							<a-form-item>
									<a-button 
										type="primary" html-type="submit" 
										:disabled="form.password.length < 6" 
										style="margin-left: auto; width: '10rem'"
                    :loading="loadingForm"
									>ยืนยัน</a-button>
							</a-form-item>
					</a-form>
			</a-modal>
		</a-menu>
	</div>
</template>
<script>
import UserService from "../api/UserService";
import SystemService from "../api/SystemService";
import Swal from "sweetalert2";
export default {
	data() {
		return {
			collapsed: false,
			selectedKeys: ["0"],
			openKeys: ["sub1"],
			preOpenKeys: ["sub1"],
			logo: require("@/assets/images/vecabo-label.png"),
			user_profile: null,
			links: [
				{
					key: "dashboard",
					name: "แดชบอร์ด",
					path: "/app/dashboard",
					icon: "speed",
					collapse: [],
				},
				{
					key: "calendar",
					name: "ตารางงาน",
					path: "/app/job/calendar",
					icon: "calendar_month",
					collapse: [],
				},
				{
					key: "schedule",
					name: "ตารางการใช้รถ",
					path: "/schedules",
					icon: "event_available",
					newtab: true,
					collapse: [],
				},
				{
					key: "manage_tour",
					name: "จัดการลูกค้าและกรุ๊ปทัวร์",
					icon: "group",
					collapse: [
						{
							name: "ลูกค้าหรือบริษัท",
							path: "/app/customer/all",
						},
						{
							name: "ผู้ประสานงาน",
							path: "/app/vehicle/ClientContact",
						},
						{
							name: "ประเภทกลุ่มลูกค้า",
							path: "/app/customer/TourType",
						},
						{
						    name: 'ประวัติลูกค้าภายนอก',
						    path: '/app/customer/OutsideCustomer'
						},
						// {
						// 	name: "ไกด์",
						// 	path: "/app/vehicle/Guide",
						// },
					],
				},
				{
					key: "quotation",
					name: "ใบเสนอราคา",
					icon: "request_quote",
					collapse: [
						{
							name: "ใบเสนอราคา",
							path: "/app/quotation/all",
						},
						{
							name: "จัดการหมายเหตุ",
							path: "/app/quotation-remark/all",
						},
					],
				},
				{
					key: "billing",
					name: "การวางบิล",
					icon: "payments",
					collapse: [
						{
							name: "รอวางบิล",
							path: "/app/manage/booking",
						},
						{
							name: "บิลค้างชำระ",
							path: "/app/manage/billing",
						},
						{
							name: "รายงานประจำเดือน",
							path: "/app/manage/monthly-report",
						},
						{
							name: "หลักค้ำประกันสัญญางาน",
							path: "/app/manage/guarantee",
						},
					],
				},
				{
					key: "booking",
					name: "การจองจัดรถ",
					path: "/app/booking/all",
					icon: "book",
					collapse: [],
				},
				{
					key: "company",
					name: "จัดการข้อมูลที่เกี่ยวกับยานพาหนะ",
					icon: "directions_car",
					collapse: [
						{
							name: "บริษัทรถ",
							path: "/app/vehicle/CarCompany",
						},
						{
							name: "ชนิดรถ",
							path: "/app/vehicle/CarType",
						},
						{
							name: "ประเภทที่นั่งรถ",
							path: "/app/vehicle/CarGroup",
						},
						{
							name: "ทะเบียนประวัติพนักงานรถ",
							path: "/app/vehicle/Driver",
						},
						{
							name: "เบี้ยเลี้ยง",
							path: "/app/vehicle/DriverCost",
						},
						{
							name: "รถทั้งหมด",
							path: "/app/vehicle/Car",
						},
						{
							name: "จุดจอดยานพาหนะทั้งหมด",
							path: "/app/vehicle/Parking",
						},
						{
							name: "ค่าใช้จ่ายรถเพิ่มเติม",
							path: "/app/vehicle/Expense",
						},
						{
							name: "รายการเพิ่มเติมใบเสนอราคา",
							path: "/app/vehicle/Extra",
						},
						{
							name: "จุดประสงค์การเดินทาง (รูปแบบการเดินทาง)",
							path: "/app/vehicle/Trip",
						},
						{
							name: "รายงานวันหมดอายุรถ",
							path: "/app/vehicle/Expire?table=Car",
						},
					],
				},
				// {
				// 	key: "manage_sales",
				// 	name: "จัดการงานขาย",
				// 	icon: "support_agent",
				// 	collapse: [
				// 		{
				// 			name: "พนักงานบริการลูกค้า CS",
				// 			path: "/app/Sale/all",
				// 		},
				// 	],
				// },
				{
					key: "manage_car_parts",
					name: "จัดการอะไหล่",
					icon: "precision_manufacturing",
					collapse: [
						{
							name: "ประเภทอะไหล่",
							path: "/app/auto-parts/PartType",
						},
						{
							name: "หน่วยของอะไหล่",
							path: "/app/auto-parts/PartUnit",
						},
						{
							name: "ผู้ขาย (vendor)",
							path: "/app/auto-parts/Vendor",
						},
						{
							name: "ช่าง",
							path: "/app/auto-parts/Technician",
						},
						{
							name: "คลังอะไหล่",
							path: "/app/auto-parts/Part",
						},
						{
							name: "เบิกจ่ายอะไหล่",

							path: "/app/auto-parts/StockHistory",
						},
						{
							name: "ประวัติเบิก-ถอนอะไหล่",
							path: "/app/auto-parts/PartHistory",
						},
					],
				},
				{
					key: "manage_repair_main",
					name: "รายการแจ้งซ่อม",
					path: "/app/repair/all",
					icon: "build",
					collapse: [],
				},
				{
					key: "manage_repair",
					name: "แจ้งซ่อม/อุบัติเหตุ",
					icon: "build_circle",
					collapse: [
						{
							name: "รายการแจ้งอุบัติเหตุ",
							path: "/app/accident/all",
						},
						{
							name: "ประเภทการซ่อม",
							path: "/app/repair-type/all",
						},
					],
				},
				{
					key: "gas_station",
					name: "ปั๊มน้ำมัน",
					icon: "local_gas_station",
					path: "/app/gas-station",
					collapse: [],
				},
			],
			visible: false,
      loadingForm: false,
			form: {
				password: '',
			},
		};
	},
	methods: {
		toggleCollapsed() {
			this.collapsed = !this.collapsed;
			this.openKeys = this.collapsed ? [] : this.preOpenKeys;
			this.$emit("collapsed", this.collapsed);
		},
		async onFinish(values) {
			const res = await SystemService.savePassword(values); 
      if (res.status == 'success') {
        this.loadingForm = false;
        this.visible = false;
        Swal.fire({
          icon: 'success',
          title: 'เปลี่ยนรหัสผ่านสำเร็จ',
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        this.loadingForm = false;
        Swal.fire({
          icon: 'error',
          title: 'เปลี่ยนรหัสผ่านไม่สำเร็จ',
          showConfirmButton: false,
          timer: 1500
        });
      }
		},
		async get_user_profile() {
			try {
				const data = await UserService.get_user_profile();
				this.data = data;
				if (data) {
					this.user_profile = data;
					const flagCheck = data.flag_check;
					if (flagCheck === 0 || (Array.isArray(flagCheck?.data) && flagCheck.data[0] === 0)) {
						this.visible = true;
					}
					if (data.company_id === 39) {
						this.links.push({
						key: "roll_call",
						name: "ติดตามรถ",
						icon: "directions_car",
						collapse: [
							{
							name: "ติดตามรถ (Roll Call & Tracking)",
							path: "/app/tracking/all",
							},
							{
							name: "ตรวจเช็ครถ (Roll Call)",
							path: "/app/roll-call/all",
							},
						],
						},
						{
							key: "manage_users",
							name: "ผู้ใช้งาน",
							icon: "settings_accessibility",
							path: "/app/manageUsers",
							collapse: [],
						},
						{
							key: "register_admin",
							name: "สมัครสมาชิก",
							icon: "person_add",
							path: "/app/register",
							collapse: [],
						});
					} else {
						this.links.push({
						key: "roll_call",
						name: "ตรวจเช็ครถ (Roll Call)",
						icon: "ambulance",
						path: "/app/roll-call/all",
						collapse: []
						},
						{
							key: "manage_users",
							name: "ผู้ใช้งาน",
							icon: "settings_accessibility",
							path: "/app/manageUsers",
							collapse: [],
						},
						{
							key: "register_admin",
							name: "สมัครสมาชิก",
							icon: "person_add",
							path: "/app/register",
							collapse: [],
						});
					}
					this.links.push({
					key: "cr_chat",
					name: "แชทพนักงานขับรถ",
					// icon: "chat",
					path: "/app/chat",
					collapse: [],
				},)
				}
			} catch (error) {
				console.error("Failed to fetch user profile:", error);
				// จัดการข้อผิดพลาดตามที่ต้องการ เช่น การแสดงข้อความข้อผิดพลาด
			}
		},
		filter_menus(array) {
			try {
			let results = [];
			if (this.user_profile && this.user_profile.permission) {
				results = array.filter((menu) => {
					return this.user_profile.permission[menu.key] >= 1;
				});
			} else {
				results = array;
			}
			return results;
		}
		catch (error) {
			console.error("Failed to filter menus:", error);
			// จัดการข้อผิดพลาดตามที่ต้องการ เช่น การแสดงข้อความข้อผิดพลาด
			}
		},
		filteredCollapse(collapse) {
    	if (this.data?.company_id === 39) {
			return collapse.filter((item) => item.name !== "หลักค้ำประกันสัญญางาน");
			} else if (this.data?.company_id !== 39) {
			return collapse.filter((item) => item.name !== "ประวัติลูกค้าภายนอก");
			}
		return collapse;
		},
	},
	watch: {
		openKeys(_val, oldVal) {
			this.preOpenKeys = oldVal;
		},
	},
	mounted() {
		this.get_user_profile();
		this.$emit("register_toggleCollapsed", this.toggleCollapsed);
	},
};
</script>

<style>
.ant-menu-item-divider {
	border-color: rgba(0, 0, 0, 0.15) !important;
}

.ant-menu-dark {
	background-color: #343a40 !important;
}

.ant-menu-sub {
	box-shadow: inset 0 2px 8px #00000073 !important;
	background-color: #2d3238 !important;
}

.ant-menu-item-selected {
	background-color: #525c65 !important;
}
</style>