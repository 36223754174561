<template>
  <a-layout style="height: 75vh; background: lightgray; padding: 15px;">
    <div style="display: flex; border-bottom: 1px solid gray; align-items: center; gap: 10px; padding-bottom: 10px;">
      <a-avatar size="large" :src="targetUser.avatar" shape="square" />
      <a-typography-text strong>
        {{ targetUser.firstname + " " + targetUser.lastname }}
      </a-typography-text>
    </div>

    <div style="flex: 1; overflow-y: auto; padding-bottom: 15px;" ref="chatContainer" v-if="groupedMessages != null">
      <div v-for="(messages, date) in groupedMessages" :key="date">
        <div class="date-header" v-if="isToday(date)">
          วันนี้
        </div>
        <div class="date-header" v-else>
          {{ formatDate(date) }}
        </div>
        <div
          v-for="(message, index) in messages"
          :key="index"
          :class="{'received-message': message.sentBy.role == 'Driver', 'sent-message': message.sentBy.role != 'Driver'}"
        >
          <div v-if="message.sentBy.role != 'Driver'">
            <div style="display: flex; flex-direction: row; align-items: center; gap: 8px; width: auto;">
              <div class="timestamp">
                <div>
                  {{ new Date(message.createdAt).toLocaleString('th-TH', { hour: '2-digit', minute: '2-digit', hour12: false }) + ' น.' }}
                </div>
                <div>
                  {{ message.isRead ? "อ่านแล้ว" : "" }}
                </div>
              </div>
              <div class="chat-bubble">
                <div v-if="message.content">
                  {{ message.content }}
                </div>
                <div v-else-if="message.type === 'image'" @click="showPreview($event , message.file_url)" style="cursor: pointer;">
                  <img :src="message.file_url" alt="Image" style="max-width: 200px; max-height: 200px;"/>
                </div>
                <div v-else-if="message.type === 'document'" @click="openDocument(message.file_url)" style="cursor: pointer;">
                  <FileTextOutlined /> {{ message.file_name + '.' + message.file_type}}
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="message.sentBy.role == 'Driver' && (index === 0 || messages[index-1].sentBy.role != 'Driver')" 
          style="display: flex; flex-direction: row;align-items: start; gap: 8px;">
            <a-avatar size="large" :src="targetUser.avatar" />
            <div style="display: flex; flex-direction: column;">
              <a-typography-text>{{ targetUser.firstname + " " + targetUser.lastname }}</a-typography-text>
              <div style="display: flex; align-items: center; gap: 8px;">
                <div class="chat-bubble">
                  <div v-if="message.content">
                    {{ message.content }}
                  </div>
                  <div v-else-if="message.type === 'image'" @click="showPreview($event , message.file_url)" style="cursor: pointer;">
                    <img :src="message.file_url" alt="Image" style="max-width: 200px; max-height: 200px;"/>
                  </div>
                  <div v-else-if="message.type === 'document'" @click="openDocument(message.file_url)" style="cursor: pointer;">
                    {{ message.file_name + '.' + message.file_type}} <FileTextOutlined />
                  </div>
                </div>
                <div class="timestamp">
                  {{ new Date(message.createdAt).toLocaleString('th-TH', { hour: '2-digit', minute: '2-digit', hour12: false }) + ' น.' }}
                </div>
              </div>
            </div>
          </div>
          <div v-else style="display: flex; flex-direction: row; align-items: center; gap: 4px; width: auto; margin-left: 45px">
              <div class="chat-bubble">
                <div v-if="message.content">
                  {{ message.content }}
                </div>
                <div v-else-if="message.type === 'image'" @click="showPreview($event , message.file_url)" style="cursor: pointer;">
                  <img :src="message.file_url" alt="Image" style="max-width: 200px; max-height: 200px;" />
                </div>
                <div v-else-if="message.type === 'document'" @click="openDocument(message.file_url)" style="cursor: pointer;">
                  {{ message.file_name + '.' + message.file_type}} <FileTextOutlined />
                </div>
              </div>
              <div class="timestamp">
                {{ new Date(message.createdAt).toLocaleString('th-TH', { hour: '2-digit', minute: '2-digit', hour12: false }) + ' น.' }}
              </div>
          </div>
        </div>
      </div>
    </div>

    <div style="display: flex; align-items: center; gap: 10px;">
        <a-upload
        name="file"
        :before-upload="beforeUpload"
        :show-upload-list="false"
        >
        <a-button>
          <img src="../../public/img/icons/upload_file.png" alt="Upload File Icon" style="height: 24px; background: none;" />
        </a-button>
      </a-upload>
      <a-input
        v-model:value="inputText"
        placeholder="Type your message here"
        style="width: 60%; margin-right: 10px;"
        @keyup.enter="sendMessage"
      />
      <a-button type="primary" @click="sendMessage">Send</a-button>
    </div>
    <a-modal v-model:visible="previewVisible" :footer="null" :mask="false" @cancel="previewVisible = false" class="custom-modal" style="background-color: none;">
      <img :src="previewImage" alt="Preview" style="width: 100%;" />
    </a-modal>
  </a-layout>
</template>

<script>
import ChatService from '../api/ChatService';
import socketService from '../api/SocketService';
import { ref } from 'vue';
import { UploadProps } from 'ant-design-vue';
import { FileTextOutlined } from '@ant-design/icons-vue';

export default {
  name: 'ChatUser',
  components:{FileTextOutlined},
  props: {
    targetUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // fileList:[],
      previewVisible: false,
      previewImage:'',
      inputText: '',
      messages: [],
      user_profile: localStorage.getItem('user_profile') ? JSON.parse(localStorage.getItem('user_profile')) : {},
      role: localStorage.getItem('role'),
    };
  },
  computed: {
    groupedMessages() {
      return this.messages.reduce((groups, message) => {
        const date = new Date(message.createdAt).toDateString();
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(message);
        return groups;
      }, {});
    },
  },
  beforeMount() {
    // this.joinRoom(this.targetUser.channel_id);
  },
  unmounted() {
    this.leaveRoom(this.targetUser.channel_id);
  },
  watch: {
    targetUser: {
      immediate: true,
      async handler(newUser, oldUser) {
        if (oldUser) {
          this.leaveRoom(oldUser.channel_id);
        }
        if (newUser) {
          this.messages = [];
          this.joinRoom(newUser.channel_id);
          await this.fetchChatHistory(newUser.channel_id);
          // const unreadMessages = this.messages.filter(message => {
          //   return (!message.isRead || message.isRead == false) && message.sentBy.role == 'Driver'
          // });
          await this.markedAsRead()
        }
      },
    },
  },
  methods: {
    async beforeUpload(file) {
      this.uploadFile(file);
      return false;
    },
    async uploadFile(file) {
        try {
          const formData = new FormData();
          formData.append('channelId', this.targetUser.channel_id)
          formData.append('sentBy', JSON.stringify({ id: this.user_profile.id, role: this.role }))
          formData.append('files', file);
        await ChatService.uploadFile(formData)
        this.scrollToBottom();
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    },
    async fetchChatHistory(channelId) {
      try {
        const histories = await ChatService.getAllMessageByChannelId(channelId);
        if (histories) {
          this.messages = histories;
        }
        this.scrollToBottom();
      } catch (error) {
        console.error('Error fetching chat history:', error);
      }
    },
    async markedAsRead(messages = null){
      socketService.markAsRead(this.targetUser.channel_id, this.role, messages)
    },
    isToday(dateString) {
      const today = new Date();
      const date = new Date(dateString);
      return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      );
    },
    joinRoom(channelId) {
      socketService.joinRoom(channelId);
      socketService.onBroadcast((message) => {
        console.log(message)
        if(!message.isRead && message.sentBy.role == 'Driver'){
          this.markedAsRead(message)
        }
        this.messages.push(message);
        this.scrollToBottom();
      }, channelId);

      socketService.onMessageRead((data) => {
        const message = this.messages.find(m => m.messageId === data.messageId);
        if (message) {
          message.isRead = data.isRead;
        }
      });
    },
    leaveRoom(channelId) {
      socketService.leaveRoom(channelId);
      socketService.offBroadcast(channelId);
    },
    sendMessage() {
      if (this.inputText.trim() !== '') {
        socketService.sendMessage(this.targetUser.channel_id, {
          sentBy: { id: this.user_profile.id, role: this.role },
          content: this.inputText,
        });
        this.inputText = '';
        this.scrollToBottom();
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.chatContainer;
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      });
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString('th-TH', options);
    },
    showPreview(e , fileUrl) {
      e.stopPropagation();
      this.previewImage = fileUrl;
      this.previewVisible = true;
    },
    openDocument(doc){
      window.open(doc, '_blank');
    }
  },
};
</script>

<style scoped>
.sent-message {
  display: flex;
  justify-content: flex-end;
  /* align-items: center; */
  gap: 8px;
  margin-bottom: 10px;
}

.received-message {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  gap: 8px;
  margin-bottom: 10px;
}

.chat-bubble {
  max-width: 48rem;
  word-wrap:break-word;
  padding: 10px;
  border-radius: 20px;
  border-color: black;
  border: solid 1px;
}

.sent-message .chat-bubble {
  margin-right: 4px;
  color: black;
  background-color: #FFFFFF;
}
.received-message .chat-bubble {
  color: white;
  background-color: #343A40;
}

.timestamp {
  /* text-align: left; */
  font-size: 12px;
  color: gray;
  display: flex;
  flex-direction: column;
  /* margin-bottom: 5px; */
}

.date-header {
  text-align: center;
  font-size: 14px;
  color: #888;
  margin: 10px 0;
  font-weight: bold;
}

.custom-modal ::v-deep .ant-modal-content {
  box-shadow: none;
  background-color: transparent;
}
.custom-modal ::v-deep .ant-modal-body {
  padding: 0;
}
</style>
