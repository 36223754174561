<template>
    <div style="height: 100vh; padding: 15px; display: flex;">
        <div class="app-content" style="flex: 1;">
            <a-card title="ศูนย์รวมต้นทุน">
                <div>
                    <div class="google-map" ref="googleMap" style="height: 500px; width: 100%;"></div>
                </div>
                <div class="side-component">
                    <a-card id="test" style="height: 70%;">
                        <a-tabs type="card"  @change="handleTabChange" style="height: 380px;">
                            <a-tab-pane key="1" tab="งานทั้งหมด">
                                <a-input-search
                                    v-model:value="search_input"
                                    placeholder="ค้นหา Booking Number, จุดรับ, จุดส่ง"
                                    enter-button="ค้นหา"
                                />
                                <br/>
                                <div class="box-container" style="font-size: 12px;">
                                    <div v-for="(booking_car_list,listIndex) in filtered_table(booking_list)" 
                                    :key="booking_car_list.id" 
                                    >
                                        <div v-if="booking_car_list.booking_cars && booking_car_list.booking_cars.length > 0">
                                            <div v-for="(booking_car,carIndex) in booking_car_list.booking_cars"
                                            :key="booking_car.id" 
                                            >
                                                <div 
                                                v-if="booking_car.status == 8"
                                                style="display: flex; flex-direction: row; margin-top: 2px;padding: 2px;border: 1px solid rgba(0, 0, 0, 0.1);">
                                                    <div style="flex: 0.1;font-size: 16px;">
                                                        {{  getRunningNumber(listIndex, carIndex) }}.
                                                    </div>
                                                    <div style="flex: 0.5; text-align: center; color: red;font-size: x-small;">
                                                            <img src="/img/icons/bus-on.png" alt="bus-image" style="width: 45px; height: 45px;">
                                                            <br/>
                                                            {{ booking_car.car.car_no}}
                                                            <br/>
                                                            {{ booking_car.car.plate_no}}
                                                    </div>
                                                    <div style="flex: 1.5;">
                                                        <strong>Booking no :</strong> {{ booking_car_list.booking_no }}
                                                        <br/>
                                                        <strong>Booking car :</strong> {{ booking_car.booking_car_no }}
                                                        <br/>
                                                        <span v-if="booking_car.stops.length > 0">
                                                            <span v-if="booking_car.stops.filter(item => item.stop_type_id == 1).length > 0">
                                                                <strong>จุดรับ :</strong> {{ booking_car.stops.filter(item => item.stop_type_id == 1)[0].name }}
                                                                <br/>
                                                            </span>
                                                            <span v-if="booking_car.stops.filter(item => item.stop_type_id !== 1 && item.stop_type_id !== 6).length > 0">
                                                                <span v-for="stopPlace in booking_car.stops.filter(item => item.stop_type_id !== 1 && item.stop_type_id !== 6)">
                                                                    <strong>{{ getStopsType(stopPlace.stop_type_id) }} :</strong> {{stopPlace.name}}
                                                                    <br/>
                                                                </span>
                                                            </span>
                                                            <span v-if="booking_car.stops.filter(item => item.stop_type_id == 6).length > 0">
                                                                <strong>จุดส่ง :</strong> {{ booking_car.stops.filter(item => item.stop_type_id == 6)[0].name }}
                                                                <br/>
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div style="flex: 0.5;color: red;text-align: right;margin-right: 2%;">
                                                        {{ Math.round(booking_car.distance/1000) }} Km
                                                        <br/>
                                                        {{ convertSecondsToHoursAndMinutes(booking_car.duration) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a-tab-pane>
                            <a-tab-pane key="2" tab="Tracking">
                                <a-input-search
                                    v-model:value="search_input"
                                    placeholder="ค้นหา Booking Number, จุดรับ, จุดส่ง"
                                    enter-button="ค้นหา"
                                />
                                <br/>
                                <div class="box-container" style="font-size: 14px;">
                                    <div v-for="(booking_car_list,listIndex) in filtered_table(booking_list)" 
                                    :key="booking_car_list.id" 
                                    >
                                        <div v-if="booking_car_list.booking_cars && booking_car_list.booking_cars.length > 0">
                                            <div 
                                            v-for="(booking_car,carIndex) in booking_car_list.booking_cars"
                                            :key="booking_car.id" >
                                                <div style="display: flex ; flex-direction: row; margin-top: 2px;padding: 10px;border: 1px solid rgba(0, 0, 0, 0.1);"
                                                    class="tracking-div"
                                                    v-if="[4, 9, 10].includes(booking_car.status)"
                                                    @click="zoomToCarLocation(booking_car)">
                                                    <div style="flex: 0.1;font-size: 16px;">
                                                        {{  getRunningNumber(listIndex, carIndex) }}.
                                                    </div>
                                                    <div style="flex: 0.5; text-align: center;font-size: 13px;">
                                                            <img src="/img/icons/bus-on.png" alt="bus-image" style="width: 45px; height: 45px;">
                                                            <br/>
                                                            {{ convertSecondsToHoursAndMinutesNumeric(booking_car.duration) }}
                                                    </div>
                                                    <div style="flex: 1.5;">
                                                        <strong>{{  booking_car.car.car_no }} | {{ booking_car.car.plate_no }}</strong>
                                                        <br/>
                                                        <span v-if="booking_car.stops.length > 0">
                                                            <span v-if="booking_car.stops.filter(item => item.stop_type_id == 1).length > 0">
                                                                 {{ booking_car.stops.filter(item => item.stop_type_id == 1)[0].name }}
                                                                <br/>
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div style="flex: 0.5;color: red;text-align: right;margin-right: 2%;">
                                                        {{ Math.round(booking_car.distance/1000) }} Km/h
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a-tab-pane>
                            <a-tab-pane key="3" tab="History">
                                <a-input-search
                                    v-model:value="search_input"
                                    placeholder="ค้นหา Booking Number, จุดรับ, จุดส่ง"
                                    enter-button="ค้นหา"
                                />
                                <br/>
                                <div class="box-container" style="font-size: 12px;">
                                    <div v-for="(booking_car_list,listIndex) in filtered_table(booking_list)" 
                                    :key="booking_car_list.id" 
                                    >
                                        <div v-if="booking_car_list.booking_cars && booking_car_list.booking_cars.length > 0">
                                            <div v-for="(booking_car,carIndex) in booking_car_list.booking_cars"
                                            :key="booking_car.id" 
                                            >
                                                <div style="display: flex; flex-direction: row; margin-top: 2px;padding: 2px;border: 1px solid rgba(0, 0, 0, 0.1);"
                                                v-if="[5, 6, 14, 15].includes(booking_car.status)">
                                                    <div style="flex: 0.1;font-size: 16px;">
                                                        {{  getRunningNumber(listIndex, carIndex) }}.
                                                    </div>
                                                    <div style="flex: 0.5; text-align: center; color: red;font-size: x-small;">
                                                            <img v-if="[6, 14].includes(booking_car.status)" src="/img/icons/bus-cancel.png" alt="bus-image" style="width: 45px; height: 45px;">
                                                            <img v-else src="/img/icons/bus-on.png" alt="bus-image" style="width: 45px; height: 45px;">
                                                            <br/>
                                                            {{ booking_car.car.car_no}}
                                                            <br/>
                                                            {{ booking_car.car.plate_no}}
                                                    </div>
                                                    <div style="flex: 1.5;">
                                                        <strong>{{formatDate(booking_car.time_start)}}</strong>
                                                        <br/>
                                                        <strong>Booking no :</strong> {{ booking_car_list.booking_no }}
                                                        <br/>
                                                        <strong>Booking car :</strong> {{ booking_car.booking_car_no }}
                                                        <br/>
                                                        <span v-if="booking_car.stops.length > 0">
                                                            <span v-if="booking_car.stops.filter(item => item.stop_type_id == 1).length > 0">
                                                                <strong>จุดรับ :</strong> {{ booking_car.stops.filter(item => item.stop_type_id == 1)[0].name }}
                                                                <br/>
                                                            </span>
                                                            <span v-if="booking_car.stops.filter(item => item.stop_type_id !== 1 && item.stop_type_id !== 6).length > 0">
                                                                <span v-for="stopPlace in booking_car.stops.filter(item => item.stop_type_id !== 1 && item.stop_type_id !== 6)">
                                                                    <strong>{{ getStopsType(stopPlace.stop_type_id) }} :</strong> {{stopPlace.name}}
                                                                    <br/>
                                                                </span>
                                                            </span>
                                                            <span v-if="booking_car.stops.filter(item => item.stop_type_id == 6).length > 0">
                                                                <strong>จุดส่ง :</strong> {{ booking_car.stops.filter(item => item.stop_type_id == 6)[0].name }}
                                                                <br/>
                                                            </span>
                                                            <span 
                                                                v-if="(booking_car_list.status == 6 || booking_car_list.status == 14) && booking_car_list.booking_cancel_remark != null" 
                                                                style="color: red;">
                                                                <strong>ยกเลิก เนื่องจาก : {{ booking_car.booking_cancel_remark }} </strong>
                                                                <br/>
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div style="flex: 0.5;color: red;text-align: right;margin-right: 2%;">
                                                        {{ Math.round(booking_car.distance/1000) }} Km/h
                                                        <br/>
                                                        {{ convertSecondsToHoursAndMinutes(booking_car.duration) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a-tab-pane>
                        </a-tabs>
                    </a-card>
                </div>
            </a-card>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";
import BookingService from "../../api/BookingService";
import GoogleMapsApiLoader from 'google-maps-api-loader';
import StopTypeService from "../../api/StopTypeService";

export default {
  props: {
    mapConfig: Object,
    apiKey: String,
  },
  data() {
    return {
      pagination: {
        onpage: 1,
        perPage: 10,
      },
      datetime: null,
      search_input: null,
      booking_list: [],
      all_booking_list: [],
      status_booking: [8],
      google: null,
      map: null,
      indexCar:0,
      markers: [], // Array to hold markers for each location
      polylines: [],
      typeStops:[],
    };
  },
  methods: {
    filtered_table(array) {
        return array.filter(
          ({ booking_no, start_location_name, end_location_name, client_name }) => {
            return this.search_input
              ? start_location_name?.includes(this.search_input) ||
                  end_location_name?.includes(this.search_input) ||
                  booking_no.includes(this.search_input) ||
                  client_name.includes(this.search_input)
              : true;
          }
        )
    },
    async init_all_bookings(){
        this.$message.loading({ content: "กำลังโหลดแผนที่...", key: "loading" });
        let all_datas = null;
            all_datas = await BookingService.get_bookings(
                this.pagination.onpage,
                this.pagination.perPage,
                1,
                { all_status: 1 }
            );
        this.all_booking_list = all_datas.data;
        this.$message.success({ content: "โหลดข้อมูลแผนที่สำเร็จ", key: "loading" });
    },
    async init_bookings() {
      this.$message.loading({ content: "กำลังโหลด...", key: "loading" });
      let datas = null;
      let datas2 = null;


      if (this.status_booking.length>0) {
        // Fetch bookings based on date range
        datas = await BookingService.get_bookings(
          this.pagination.onpage,
          this.pagination.perPage,
          1,
          { status_booking: this.status_booking }
        );
      } else {
        // Fetch bookings without date filter
        datas = await BookingService.get_bookings(
          this.pagination.onpage,
          this.pagination.perPage,
          1
        );
      }
      const combinedData = {
        data: [...(datas ? datas.data : []), ...(datas2 ? datas2.data : [])],
        total: (datas ? datas.total : 0) + (datas2 ? datas2.total : 0),
        page: this.pagination.onpage,
        perPage: this.pagination.perPage,
      };

      this.booking_list = combinedData.data;
      console.log("list is come", this.booking_list);
      this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
    },
    async fetchRoute(start, end) {
        const directionsService = new this.google.maps.DirectionsService();
        const request = {
            origin: start,
            destination: end,
            travelMode: this.google.maps.TravelMode.DRIVING, // Change this to WALKING or BICYCLING if needed
        };

        try {
            const result = await directionsService.route(request);
            return result.routes[0].legs[0].steps; // Return the steps of the route
        } catch (error) {
            console.error('Error fetching directions:', error);
            return [];
        }
    },
    async fetchTypeStop(){
        let data = await StopTypeService.getStopTypeAll()
        this.typeStops = data
    },
    getStopsType(id){
        return this.typeStops[id-1].name
    },
    handleTabChange(key) {
        this.search_input = null;
      // Update status_booking based on the selected tab key
      if (key === '1') {
        this.status_booking = [8];
      } else if (key === '2') {
        this.status_booking = [4,9,10];
      } else if (key === '3') {
        this.status_booking = [5, 6, 14, 15];
      }
      this.init_bookings();
    },
    convertSecondsToHoursAndMinutes(seconds) {
        var text = ''
        const hours = Math.floor(seconds / 3600); // Calculate the number of hours
        if(hours >= 1){
            text += hours + ' h' + (hours !== 1 ? 's' : '')
        }
        const minutes = Math.floor((seconds % 3600) / 60); // Calculate remaining minutes
        if(minutes >= 1){
            text += ' ' + minutes + ' min' + (minutes !== 1 ? 's' : '')
        }
        return text;
    },
    convertSecondsToHoursAndMinutesNumeric(seconds) {
        const hours = Math.floor(seconds / 3600); // Calculate the number of hours
        const minutes = Math.floor((seconds % 3600) / 60); // Calculate remaining minutes

        // Format hours and minutes with leading zeros
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}`;
    },
    formatDate(date) {
      return dayjs(date).format('DD/MM/YYYY HH:mm');
    },
    getRunningNumber(listIndex, carIndex) {
        let index = 0;
        const filteredBookingList = this.filtered_table(this.booking_list);
        // Calculate total cars before the current list
        for (let i = 0; i < listIndex; i++) {
            if (filteredBookingList[i].booking_cars) {
                // Loop through each car in booking_cars
                filteredBookingList[i].booking_cars.forEach(car => {
                    // Check if car status is included in status_booking
                    if (this.status_booking.includes(car.status)) {
                        index += 1; // Increment index for each valid car
                    }
                });
            }
        }

        // Now count the valid cars in the current booking (listIndex)
        if (filteredBookingList[listIndex].booking_cars) {
            for (let j = 0; j <= carIndex; j++) {
                const car = filteredBookingList[listIndex].booking_cars[j];
                if (this.status_booking.includes(car.status)) {
                    index += 1; // Increment index for each valid car in the current booking
                }
            }
        }

        return index; // Return the total count
    },
    zoomToCarLocation(booking_car) {
        if (booking_car && booking_car.stops && booking_car.stops.length > 0 && (booking_car.stops[0].lat && booking_car.stops[0].lng)) {
            const firstStop = booking_car.stops[0];
            const position = { lat: Number(firstStop.lat), lng: Number(firstStop.lng) };
            // Zoom to the stop's position
            this.map.setCenter(position);
            this.map.setZoom(18);
            this.map.panTo(position);

            // Reset markers instead of clearing
            this.resetMarkers(booking_car.stops);
            // Reset or draw the polyline if there are at least two stops
            if (booking_car.stops.length > 1) {
                this.resetPolylines(booking_car.stops);
            }
        }
    },

    resetMarkers(stops) {
        // Update existing markers or create new ones
        for (let i = 0; i < stops.length; i++) {
            const stopPlace = stops[i];
            if (i < this.markers.length) {
                // Update existing marker position
                this.markers[i].setPosition({ lat: Number(stopPlace.lat), lng: Number(stopPlace.lng) });
            } else {
                // Create a new marker if there are more stops than existing markers
                const marker = new this.google.maps.Marker({
                    position: { lat: Number(stopPlace.lat), lng: Number(stopPlace.lng) },
                    map: this.map,  
                    title: 'Location',
                });
                this.markers.push(marker); // Store the new marker
                this.markers[this.markers.length-1].setVisible(true)
            }
        }

        // If there are fewer stops than existing markers, remove excess markers
        if (this.markers.length > stops.length) {
            for(let i = stops.length ; i < this.markers.length ; i++){
                this.markers[i].setMap(null);
                this.markers[i].setVisible(false);
                this.markers.pop()
            }
        }
    },
    resetPolylines(stops) {
        // If there is an existing polyline, remove it
        if (this.polylines.length > 0) {
            const existingPolyline = this.polylines[0];
            
            // Remove the polyline from the map
            existingPolyline.setMap(null); // Assuming you're using Google Maps API

            // Clear the polyline from the polylines array
            this.polylines = []; 
        }
        
        // Create a new polyline with the updated path
        this.drawTrafficPolyline(stops);
    },
    drawTrafficPolyline(stops) {
        const directionsService = new this.google.maps.DirectionsService();
        
        // Check for the number of stops to handle accordingly
        const waypoints = stops.length > 2 
            ? stops.slice(1, stops.length - 1).map(stop => ({
                location: new this.google.maps.LatLng(Number(stop.lat), Number(stop.lng)),
                stopover: true,
            }))
            : []; // No waypoints if there are 2 or fewer stops

        const request = {
            origin: new this.google.maps.LatLng(Number(stops[0].lat), Number(stops[0].lng)),
            destination: new this.google.maps.LatLng(Number(stops[stops.length - 1].lat), Number(stops[stops.length - 1].lng)),
            waypoints: waypoints,
            travelMode: this.google.maps.TravelMode.DRIVING,
            drivingOptions: {
                departureTime: new Date(Date.now()), // For the time of the request
                trafficModel: 'bestguess', // Choose 'bestguess', 'pessimistic', or 'optimistic'
            },
        };
        
        directionsService.route(request, (result, status) => {
            if (status === this.google.maps.DirectionsStatus.OK) {
                this.drawPolylineFromDirections(result.routes[0]);
            } else {
                console.error('Error fetching directions:', status);
            }
        });
    },

    drawPolylineFromDirections(route) {
        // Create an array to hold the path coordinates
        const path = [];

        // Loop through each leg of the route
        route.legs.forEach(leg => {
            // Push the start location of the leg
            path.push({
                lat: leg.start_location.lat(),
                lng: leg.start_location.lng(),
            });

            // Loop through each step in the leg to include all points
            leg.steps.forEach(step => {
                path.push({
                    lat: step.end_location.lat(),
                    lng: step.end_location.lng(),
                });
            });
        });

        // Create and configure the polyline
        const polyline = new this.google.maps.Polyline({
            path: path,
            geodesic: true,
            strokeColor: '#000dff',
            strokeOpacity: 1.0,
            strokeWeight: 6,
        });

        polyline.setMap(this.map); // Add polyline to the map
        this.polylines.push(polyline); // Store the polyline
    },
    initializeMap() {
        const mapContainer = this.$refs.googleMap;
        this.map = new this.google.maps.Map(mapContainer, {
            center: { lat: 13.736717, lng: 100.523186 }, // Replace with your desired coordinates
            zoom: 15,
            mapTypeControl: false, // Disable map type control
            fullscreenControl: false, // Disable fullscreen button (located at the right side)
            zoomControl: false, // Disable zoom control (usually on the right side)
            streetViewControl: false,
            ...this.mapConfig,
        });
        // Initialize the Autocomplete and link it to the input element.
        const input = document.createElement('input');
        input.type = 'text';
        input.placeholder = 'Search for a place';
        input.style.padding = '10px 15px'; 
        input.className = 'controls'; // Add any classes you want for styling
        input.style.width = "20%"
        input.style.height = "8%"
        input.style.border = '1px solid #ccc';
        input.style.borderRadius = '25px';
        input.style.margin = "1%"
        input.style.backgroundImage = "url('/img/icons/search-icon.png')"; // Path to your search icon
        input.style.backgroundSize = '20px 20px'; // Size of the icon
        input.style.backgroundRepeat = 'no-repeat'; // Prevent repeat
        input.style.backgroundPosition = '95% center'; // Position the icon
        this.map.controls[this.google.maps.ControlPosition.TOP_LEFT].push(input);

        const autocomplete = new this.google.maps.places.Autocomplete(input);
        
        // Bias the Autocomplete results towards the current map's viewport.
        this.map.addListener('bounds_changed', () => {
            autocomplete.setBounds(this.map.getBounds());
        });
        var numberOn = 0
        var numberGoing = 0
        var numberOff = 0
        var other = 0
        for (const booking of this.all_booking_list) {
            if (booking.booking_cars.length > 0) {
                for (const car of booking.booking_cars) {
                    if([8].includes(car.status)){
                        numberOn++
                    }else if([4,9,10].includes(car.status)){
                        numberGoing++
                    }else if([5,6,14,15].includes(car.status)){
                        numberOff++
                    }else{
                        other++
                    }
                    if (car.stops && car.stops.length > 0 && (car.stops[0].lat && car.stops[0].lng)) {
                        // Assuming you want to log or use the first stop's lat/lng
                        let url_icon= ''
                        if(car.status == 6 || car.status == 14){
                            url_icon = '/img/icons/truck-top-gray.png'
                        }else{
                            url_icon = '/img/icons/truck-top-green.png'
                        }

                        //Optionally, you can add a marker to the map for each stop
                        const marker = new this.google.maps.Marker({
                            position: { lat: Number(car.stops[0].lat), lng: Number(car.stops[0].lng) },
                            map: this.map,
                            title: "Car Stop", // You can customize the title
                            icon: {
                                url:  url_icon, // URL of the custom icon
                                scaledSize: new this.google.maps.Size(50, 50), // Size of the icon
                                anchor: new this.google.maps.Point(25, 25) // Anchor point for rotation
                            }
                        });
                    }
                }
            }
        }
        console.log("on:",numberOn," going:",numberGoing," off:",numberOff," ohter:",other)
        // Listen for the event fired when the user selects a prediction.
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (place.geometry) {
            this.map.setCenter(place.geometry.location);
            this.map.setZoom(18); // Zoom in on the selected place
            }
        });
        },
  },
  async mounted() {
    await this.init_all_bookings();
    await this.init_bookings();
    await this.fetchTypeStop()

    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: 'AIzaSyDlh2GB-hcJWl3Q9vADW0PgxhdH74ubPqw',
      libraries: ['places'], 
    });
    this.google = googleMapApi;
    this.initializeMap();
  },
};
</script>

<style scoped>
.google-map {
  height: 500px; /* Height for the Google Map */
  width: 100%; /* Width for the Google Map */
}
.side-component {
  position: absolute;
  top: 26%; /* Adjust as needed */
  right: 2%; /* Adjust as needed */
  z-index: 10; /* Ensure it's above other content */
  width: 38%; /* Set width as needed */
  height: 100%;
}
.box-container{
    max-height: 280px;
    overflow-y: scroll;
}
.tracking-div {
    cursor: pointer; /* Change cursor to pointer on hover */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transition */
}

.tracking-div:hover {
    background-color: rgba(0, 0, 0, 0.1); /* Change background color on hover */
    transform: scale(1.02); /* Slightly scale up on hover */
}
</style>