<template>
  <div class="dashboard-container">
    <div class="app-content">
      <div class="header sticky">
        <h1>แดชบอร์ด</h1>
        <div style="display: flex">
          <a-range-picker v-model:value="dateRange" style="margin-right: 10px" format="DD/MM/YYYY" />
          <a-button @click="loadPage(false)" style="width: fit-content" type="primary"
            class="primary-button">ค้นหา</a-button>
        </div>
      </div>

      <div class="card-container">
        <div class="dashboard-card bg-blue">
          <span class="material-symbols-outlined icon">database</span>
          <span>จำนวน Booking ทั้งหมด</span>
          <span>{{ bookingCount }}</span>
        </div>

        <div class="dashboard-card bg-blue">
          <span class="material-symbols-outlined icon">database</span>
          <span>จำนวน Booking ที่ถูกยกเลิก</span>
          <span>{{ canceledBookings }}</span>
        </div>

        <div class="dashboard-card bg-green">
          <span class="material-symbols-outlined icon">database</span>
          <span>รายได้สุทธิ</span>
          <span>{{ billingAmount }}</span>
        </div>

        <div class="dashboard-card bg-orange">
          <span class="material-symbols-outlined icon">database</span>
          <span>Biling ที่รอการเรียกเก็บ</span>
          <span>{{ billingIncompleteAmount.toLocaleString() }}</span>
        </div>
      </div>

      <a-row>
        <a-col :span="16">
          <div class="chart-container">
            <div class="chart-header bg-yellow" style="color: white">รายรับ-รายจ่าย</div>
            <div class="chart-content">
              <canvas ref="income_outcome"></canvas>
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="chart-container">
            <div class="chart-header bg-yellow" style="color: white">สถานะการจองจัดรถ</div>
            <div class="chart-content">
              <canvas ref="booking_status_summary"></canvas>
            </div>
          </div>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <div class="chart-container">
            <div class="chart-header bg-orange" style="color: white">จำนวนทริปของยานพาหนะ</div>
            <div class="chart-content-table">
                <a-table
                  :columns="columnsTripVehicle"
                  :data-source="paginatedDataTripVehicle"
                  :pagination="paginationTripVehicle"
                  :sorter="sorterTripVehicle"
                  :loading="loadingTripVehicle"
                  @change="handleTableChangeTripVehicle"
                  size="small"
                >
                <template #bodyCell="{ column, record, index }">
                  <template v-if="column.key === 'day'">
                      {{ String(record.day).split('.')[0] }}
                  </template>
                </template>
                <template #summary>
                  <a-table-summary fixed="bottom">
                      <a-table-summary-row style="background-color:#E8E8E8">
                        <a-table-summary-cell></a-table-summary-cell>
                        <a-table-summary-cell>รวม</a-table-summary-cell>
                        <a-table-summary-cell>
                            {{ Number(dataSourceTripVehicle.reduce((prev, curr) => Number(prev) + Number(curr.work), 0)).toLocaleString() }}
                        </a-table-summary-cell>
                        <a-table-summary-cell>
                            {{ Number(dataSourceTripVehicle.reduce((prev, curr) => Number(prev) + Number(curr.day), 0)).toLocaleString() }}
                        </a-table-summary-cell>
                      </a-table-summary-row>
                  </a-table-summary>
                </template>
              </a-table>
              </div>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="chart-container">
            <div class="chart-header bg-orange" style="color: white">จำนวนทริปของพนักงานขับรถ</div>
            <div class="chart-content-table">
              <a-table
                :columns="columnsTripDriver"
                :data-source="paginatedDataTripDriver"
                :pagination="paginationTripDriver"
                :sorter="sorterTripDriver"
                :loading="loadingTripDriver"
                @change="handleTableChangeTripDriver"
                size="small"
              >
                <template #bodyCell="{ column, record, index }">
                  <template v-if="column.key === 'driver_cost'">
                      {{ Number(String(record.driver_cost).split('.')[0]).toLocaleString() }}
                  </template>
                </template>
                <template #summary>
                    <a-table-summary fixed="bottom">
                        <a-table-summary-row style="background-color:#E8E8E8">
                          <a-table-summary-cell></a-table-summary-cell>
                          <a-table-summary-cell>รวม</a-table-summary-cell>
                          <a-table-summary-cell>
                              {{ Number(dataSourceTripDriver.reduce((prev, curr) => Number(prev) + Number(curr.amount), 0)).toLocaleString() }}
                          </a-table-summary-cell>
                          <a-table-summary-cell>
                              {{ Number(dataSourceTripDriver.reduce((prev, curr) => Number(prev) + Number(curr.work_days), 0)).toLocaleString() }}
                          </a-table-summary-cell>
                          <a-table-summary-cell>
                              {{ Number(dataSourceTripDriver.reduce((prev, curr) => Number(prev) + Number(curr.driver_cost), 0)).toLocaleString() }}
                          </a-table-summary-cell>
                        </a-table-summary-row>
                    </a-table-summary>
                  </template>
              </a-table>
            </div>
          </div>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <div class="chart-container">
            <div class="chart-header bg-orange" style="color: white">ประสิทธิภาพของพนักงานขาย</div>
            <div class="chart-content-table">
              <table>
                <tr class="table-header">
                  <td>ลำดับ</td>
                  <td>ชื่อ-นามสกุล</td>
                  <td>ยอดขาย</td>
                </tr>
                <tr v-for="{ name, amount }, i in top_sales" :key="i">
                  <td>{{ i + 1 }}</td>
                  <td>{{ name }}</td>
                  <td>{{ amount.toLocaleString() }}</td>
                </tr>
                <tr class="table-divider">
                  <td colspan="2">รวม</td>
                  <td>{{ top_sales.reduce((a, b) => a + b.amount, 0).toLocaleString() }}</td>
                </tr>
                <tr v-if="!top_sales.length" style="color: #f4f4f4">
                  <td colspan="3">ไม่มีข้อมูล</td>
                </tr>
              </table>
            </div>
          </div>
        </a-col>

        <a-col :span="12">
          <div class="chart-container">
            <div class="chart-header bg-orange" style="color: white">พื้นที่ว่าง รอเพิ่มข้อมูลฝ่ายขาย</div>
            <div class="chart-content-table">
              <table>
                <tr class="table-header">
                  <td>ลำดับ</td>
                  <td>ชื่อ-นามสกุล</td>
                  <td>ยอดขาย</td>
                </tr>
                <tr v-for="{ name, amount }, i in top_sales" :key="i">
                  <td>{{ i + 1 }}</td>
                  <td>{{ name }}</td>
                  <td>{{ amount.toLocaleString() }}</td>
                </tr>
                <tr class="table-divider">
                  <td colspan="2">รวม</td>
                  <td>{{ top_sales.reduce((a, b) => a + b.amount, 0).toLocaleString() }}</td>
                </tr>
                <tr v-if="!top_sales.length" style="color: #f4f4f4">
                  <td colspan="3">ไม่มีข้อมูล</td>
                </tr>
              </table>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row justify="space-between">
        <a-col :span="6">
          <div class="chart-container">
            <div class="chart-header bg-orange" style="color: white">ประเภทรถที่ถูกใช้</div>
            <div class="chart-content">
              <canvas ref="most_used_cars"></canvas>
            </div>
          </div>
        </a-col>
        <a-col :span="6">
          <div class="chart-container">
            <div class="chart-header bg-orange" style="color: white">สถานะใบเสนอราคา</div>
            <div class="chart-content">
              <canvas ref="quotation_summary"></canvas>
            </div>
          </div>
        </a-col>

        <a-col :span="6">
          <div class="chart-container">
            <div class="chart-header bg-orange" style="color: white">การแจ้งซ่อม</div>
            <div class="chart-content">
              <canvas ref="repair_summary"></canvas>
            </div>
          </div>
        </a-col>
        <a-col :span="6">
          <div class="chart-container">
            <div class="chart-header bg-orange" style="color: white">สถานะใบการจอง</div>
            <div class="chart-content">
              <canvas ref="bookings_summary"></canvas>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <div class="chart-container">
            <div class="chart-header bg-blue" style="color: white">ยอดขาย</div>
            <div class="chart-content">
              <canvas ref="sale_summary"></canvas>
            </div>
          </div>
        </a-col>

        <a-col :span="12">
          <div class="chart-container">
            <div class="chart-header bg-blue" style="color: white">ค่าเชื้อเพลิง</div>
            <div class="chart-content">
              <canvas ref="fuel_summary"></canvas>
            </div>
          </div>
        </a-col>
      </a-row>

      <div class="chart-container">
        <div class="chart-header bg-blue" style="color: white">พนักงานขับรถ</div>
        <div class="chart-content-table">
          <a-table
                :columns="columnsSumDriver"
                :data-source="paginatedDataSumDriver"
                :pagination="paginationSumDriver"
                :sorter="sorterSumDriver"
                :loading="loadingSumDriver"
                :scroll="{ y: 700 , x: 500 }"
                @change="handleTableChangeSumDriver"
                size="small"
            >
            <template #bodyCell="{ column, record, index }">
              <template v-if="column.key === 'work_status_id'" >
                <div style="display: flex; justify-content: center;">
                  <div v-if="record.work_status_id == 3" class="circle green"></div>
                  <div v-else class="circle red"></div>
                </div>
              </template>
              <template v-if="column.key === 'work_status_today'" >
                <div v-if="record.work_status_today == 1">มีงาน</div>
                <div v-else>ว่างงาน</div>
              </template>
            </template>
          </a-table>
        </div>
      </div>

      <div class="chart-container">
        <div class="chart-header bg-blue" style="color: white">รถทั้งหมด</div>
        <div class="chart-content-table">
          <a-table
                :columns="columnsSumVehicle"
                :data-source="paginatedDataSumVehicle"
                :pagination="paginationSumVehicle"
                :sorter="sorterSumVehicle"
                :loading="loadingSumVehicle"
                :scroll="{ y: 700 , x: 500 }"
                @change="handleTableChangeSumVehicle"
                size="small"
            >
            <template #bodyCell="{ column, record, index }">
              <template v-if="column.key === 'booking_car_no'" >
                <div v-if="record.time_start_end != null">
                  <a 
                    :href="'/app/quotation/' + record.booking_id + '?edit=true'"
                    style="color: #1890ff; text-decoration: underline;"
                    target="_blank"
                  >
                      {{ record.booking_car_no }}
                  </a>
                  </div>
                <div v-else> </div>
              </template>
              <template v-if="column.key === 'booking_status'">
                  <a-tag color="red" v-if="record.booking_status == 'ยังไม่ได้รับงาน'">{{ record.booking_status }}</a-tag>
                  <a-tag color="green" v-else-if="record.booking_status">{{ record.booking_status }}</a-tag>
                  <div v-else></div>
              </template>
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import input_primary from "../../components/input_primary.vue";
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import UserService from "../../api/UserService";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
dayjs.extend(buddhistEra);
import inputDatePicker from "../../components/input_date_picker.vue";
import SocketService from "../../api/SocketService";
import SystemService from "../../api/SystemService";

export default {
  components: { input_primary, inputDatePicker },
  name: "Dashboard",
  props: {
    company_data: Object,
  },
  data() {
    return {
      // Table TripVehicle
      dataSourceTripVehicle : [],
      columnsTripVehicle: [
        {
          title: 'ลำดับ',
          dataIndex: 'index',
          key: 'index',
          width: 50,
        },
        {
          title: 'เบอร์รถ | ทะเบียนรถ',
          dataIndex: 'car_no_and_plate_no',
          key: 'car_no_and_plate_no',
        },
        {
          title: 'จำนวนงาน',
          dataIndex: 'work',
          key: 'work',
          sorter: true, 
        },
        {
          title: 'จำนวนวัน',
          dataIndex: 'day',
          key: 'day',
          sorter: true, 
        },
      ],
      paginationTripVehicle: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total) => `Total ${total} items`,
      },
      loadingTripVehicle: false,
      sortedInfoTripVehicle: null,
      paginatedDataTripVehicle: [],

      // Table TripDriver
      dataSourceTripDriver : [],
      columnsTripDriver: [
        {
          title: 'ลำดับ',
          dataIndex: 'index',
          key: 'index',
          width: 50,
        },
        {
          title: 'ชื่อ-นามสกุล',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'จำนวนงาน',
          dataIndex: 'amount',
          key: 'amount',
          sorter: true, 
        },
        {
          title: 'จำนวนวัน',
          dataIndex: 'work_days',
          key: 'work_days',
          sorter: true, 
        },
        {
          title: 'เบี้ยเลี้ยง',
          dataIndex: 'driver_cost',
          key: 'driver_cost',
          sorter: true, 
        },
      ],
      paginationTripDriver: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total) => `Total ${total} items`,
      },
      loadingTripDriver: false,
      sortedInfoTripDriver: null,
      paginatedDataTripDriver: [],

      // Table SumDriver
      dataSourceSumDriver : [],
      columnsSumDriver: [
        {
          title: 'ลำดับ',
          dataIndex: 'index',
          key: 'index',
          width: 50,
        },
        {
          title: 'ชื่อ',
          dataIndex: 'firstname',
          key: 'firstname',
        },
        {
          title: 'นามสกุล',
          dataIndex: 'lastname',
          key: 'lastname',
        },
        {
          title: 'เบอร์โทร',
          dataIndex: 'phone_no',
          key: 'phone_no',
        },
        {
          title: 'Online Status',
          dataIndex: 'work_status_id',
          key: 'work_status_id',
        },
        {
          title: 'Status',
          dataIndex: 'work_status_name',
          key: 'work_status_name',
        },
        {
          title: 'สถานะทำงาน',
          dataIndex: 'work_status_today',
          key: 'work_status_today',
          sorter: true, 
        },
        {
          title: 'Username',
          dataIndex: 'username',
          key: 'username',
        },
        {
          title: 'Rating',
          dataIndex: 'rating',
          key: 'rating',
          width: 100,
        },
      ],
      paginationSumDriver: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total) => `Total ${total} items`,
      },
      loadingSumDriver: false,
      sortedInfoSumDriver: null,
      paginatedDataSumDriver: [],
      dataSumTripVehicle:[],

      // Table Vehicle
      columnsSumVehicle: [
        {
          title: 'ลำดับ',
          dataIndex: 'index',
          key: 'index',
          width: 50,
        },
        {
          title: 'Booking',
          dataIndex: 'booking_car_no',
          key: 'booking_car_no',
          width: 100,
        },
        {
          title: 'เบอร์รถ | ทะเบียนรถ',
          dataIndex: 'car_no_and_plate_no',
          key: 'car_no_and_plate_no',
        },
        {
          title: 'คนขับ',
          dataIndex: 'fullname',
          key: 'fullname',
        },
        {
          title: 'สถานะรับงาน',
          dataIndex: 'booking_status',
          key: 'booking_status',
        },
        {
          title: 'ความพร้อมใช้งาน',
          dataIndex: 'status_ready',
          key: 'status_ready',
        },
        {
          title: 'วันเวลารับ - ส่ง',
          dataIndex: 'time_start_end',
          key: 'time_start_end',
        },
      ],
      paginationSumVehicle: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total) => `Total ${total} items`,
      },
      dataSourceSumVehicle : [],
      loadingSumVehicle: false,
      sortedInfoSumVehicle: null,
      paginatedDataSumVehicle: [],
      dataSumVehicle:[],

      dateRange: [dayjs().startOf("month"), dayjs().endOf("month")],
      // Dashboard
      billingIncompleteAmount: 0,
      billingAmount: 0,
      bookingCount: 0,
      canceledBookings: 0,
      billingOverall: [],
      bookingOverall: [],
      top_drivers: [],
      fuelOverall: [],
      incomeOverall: [],
      incomePerExpense: [],
      mostUsedVehicles: [],
      quotationOverall: [],
      top_sales: [],
      vehicleRepairOverall: [],

      // Drivers
      drivers: [],
      work_status: [],
      chart_keys: [
        "income_outcome",
        "most_used_cars",
        "quotation_summary",
        "booking_status_summary",
        "bookings_summary",
        "sale_summary",
        "fuel_summary",
        "repair_summary",
      ],
      chart_elements: [],
    };
  },
  methods: {
      connectSocket() {
          SocketService.connect();
          SocketService.joinRoom('dashboard');
          SocketService.onBroadcast((message) => {
            console.log("Socket message received");
            this.fetchDataDriverDashboard();
            // this.drivers = this.isDriverLeave(message);
          },'dashboard');
    },
    init_charts(key) {
      // ค้นหา chart ที่มี key ตรงกันใน chart_elements
      const chartIndex = this.chart_elements.findIndex((el) => el.key === key);

      // ถ้า chart เก่ามีอยู่ ให้ทำลาย chart เก่า
      if (chartIndex !== -1) {
        this.chart_elements[chartIndex].chart.destroy();
        this.chart_elements.splice(chartIndex, 1);
      }

      const canvas = this.$refs[key];
      if (!canvas) {
        console.error(`Failed to create chart: can't find canvas with ref '${key}'`);
        return;
      }

      const context = canvas.getContext('2d');
      if (!context) {
        console.error(`Failed to create chart: can't acquire context from the canvas with ref '${key}'`);
        return;
      }

      // ดึงข้อมูล chart ใหม่จาก key
      const { type, options, data } = this[key];
      const config = {
        type: type,
        data: data,
        options: options,
      };

      // สร้าง chart ใหม่และเพิ่มเข้าไปใน chart_elements
      const newChart = new Chart(context, config);
      this.chart_elements.push({ key, chart: newChart });
    },
    // isDriverLeave(item){
    //   item.forEach((driver) => {
    //     if(driver.leaves.length >= 0){
    //       driver.leaves.forEach(leave => {
    //         let current_date = dayjs().startOf("day").unix();
    //         // let current_date = dayjs("2024-08-6").startOf("day").unix();
    //         if(current_date >= dayjs(leave.time_start).startOf("day").unix() && current_date <= dayjs(leave.time_end).startOf("day").unix()){
    //             if(leave.approve == 1){
    //               driver.work_status_id = leave.work_status_id;
    //               driver.work_status = this.work_status.find((status) => status.id === driver.work_status_id);
    //             }
    //           }
    //         })
    //       }
    //   })
    //   return item
    // },
    async get_data() {
      // this.drivers = await UserService.GetDrivers();
      this.work_status = await SystemService.get_all('WorkStatus');
      // this.drivers = this.isDriverLeave(this.drivers);
    },
    async get_dashboard() {
      const dashboard = await UserService.get_dashboard(
        this.dateRange[0].format("DD/MM/YYYY"),
        this.dateRange[1].format("DD/MM/YYYY")
      );
      this.create_charts(dashboard);
    },
    async create_charts(dashboard) {
      for (const key in dashboard) {
        if (dashboard.hasOwnProperty(key)) {
          this[key] = dashboard[key];
          if (this.chart_keys.includes(key)) {
            this.init_charts(key);
          }
        }
      }
    },
    async loadPage() {
      this.$message.loading({ content: "Loading...", key: "load", duration: 0 });
      await this.get_data();
      await this.get_dashboard();
      this.clearData();
      await this.fetchDataTripVehicle();    
      await this.fetchDataTripDriver();
      await this.fetchDataDriverDashboard();
      await this.fetchDataVehicleDashboard();
      
      this.$message.destroy("load");
    },
    clearData() {
      this.dataSourceTripVehicle = [];
      this.dataSourceTripDriver = [];
      this.dataSourceSumDriver = [];
      this.dataSourceSumVehicle = [];
    },
    handleTableChangeTripVehicle(pagination, filters, sorter) {
      const { current, pageSize } = pagination;
      let sortedData = [...this.dataSourceTripVehicle]; // เตรียมข้อมูลที่จะทำการ sort

      // ถ้ามีการ sort ให้ทำการ sort ข้อมูล
      if (sorter.order) {
        sortedData.sort((a, b) => {
          if (sorter.field === 'work'){ // ถ้าเป็น work ที่ต้องการ sort
            return sorter.order === 'ascend' ? a.work - b.work : b.work - a.work;
          }else if (sorter.field === 'day'){ // ถ้าเป็น day ที่ต้องการ sort
            return sorter.order === 'ascend' ? a.day - b.day : b.day - a.day;
          }
          return 0;
        });
      }

      this.paginationTripVehicle.current = current; // กำหนดค่า current ใหม่
      this.paginatedDataTripVehicle.pageSize = pageSize; // กำหนดค่า pageSize ใหม่
      this.paginatedDataTripVehicle = sortedData // กำหนดข้อมูลที่จะแสดงใน table
    },
    handleTableChangeSumDriver(pagination, filters, sorter) {
      const { current, pageSize } = pagination;
      let sortedData = [...this.dataSourceSumDriver]; // เตรียมข้อมูลที่จะทำการ sort

      if (sorter.order) { // ถ้ามีการ sort ให้ทำการ sort ข้อมูล
        sortedData.sort((a, b) => {
          if (sorter.field === 'work_status_today'){ // ถ้าเป็น amount ที่ต้องการ sort
            return sorter.order === 'ascend' ? a.work_status_today - b.work_status_today : b.work_status_today - a.work_status_today;
          }else{
            return 0;
          }
        });
      }

      this.paginationSumDriver.current = current; // กำหนดค่า current ใหม่
      this.paginationSumDriver.pageSize = pageSize; // กำหนดค่า pageSize ใหม่
      this.paginatedDataSumDriver = sortedData // กำหนดข้อมูลที่จะแสดงใน table
    },
    handleTableChangeSumVehicle(pagination, filters, sorter) {
      const { current, pageSize } = pagination;
      let sortedData = [...this.dataSourceSumVehicle]; // เตรียมข้อมูลที่จะทำการ sort
      this.paginationSumVehicle.current = current; // กำหนดค่า current ใหม่
      this.paginationSumVehicle.pageSize = pageSize; // กำหนดค่า pageSize ใหม่
      this.paginatedDataSumVehicle = sortedData // กำหนดข้อมูลที่จะแสดงใน table
    },
    handleTableChangeTripDriver(pagination, filters, sorter) {
      const { current, pageSize } = pagination;
      let sortedData = [...this.dataSourceTripDriver]; // เตรียมข้อมูลที่จะทำการ sort

      if (sorter.order) { // ถ้ามีการ sort ให้ทำการ sort ข้อมูล
        sortedData.sort((a, b) => {
          if (sorter.field === 'amount'){ // ถ้าเป็น amount ที่ต้องการ sort
            return sorter.order === 'ascend' ? a.amount - b.amount : b.amount - a.amount;
          }else if (sorter.field === 'work_days'){  // ถ้าเป็น work_days ที่ต้องการ sort
            return sorter.order === 'ascend' ? a.work_days - b.work_days : b.work_days - a.work_days;
          }else if (sorter.field === 'driver_cost'){ // ถ้าเป็น driver_cost ที่ต้องการ sort
            return sorter.order === 'ascend' ? a.driver_cost - b.driver_cost : b.driver_cost - a.driver_cost;
          }
          return 0;
        });
      }

      this.paginationTripDriver.current = current; // กำหนดค่า current ใหม่
      this.paginationTripDriver.pageSize = pageSize; // กำหนดค่า pageSize ใหม่
      this.paginatedDataTripDriver = sortedData // กำหนดข้อมูลที่จะแสดงใน table
    },
    async fetchDataTripDriver() {
      const getSumTripDriver = await UserService.getSumTripDriver(this.dateRange[0].format("DD/MM/YYYY"),this.dateRange[1].format("DD/MM/YYYY"));
      this.dataSumTripDriver = getSumTripDriver;
      this.paginatedDataTripDriver = this.dataSourceTripDriver
      this.paginatedDataTripDriver = this.dataSourceTripDriver
      this.dataSumTripDriver.forEach((item , index)=>{
        const data = {
          index: index + 1,
          name: item.fullname,
          amount: Number(item.work),
          work_days: Number(item.day),
          driver_cost: item.driver_cost,
        };
        this.dataSourceTripDriver.push(data);
      });
      this.paginatedDataSumDriver = this.dataSourceTripDriver;
      this.paginatedDataTripDriver.total = this.dataSourceTripVehicle.length;
    },
    async fetchDataTripVehicle() {
      const getSumTripVehicle = await UserService.getSumTripVehicle(this.dateRange[0].format("DD/MM/YYYY"),this.dateRange[1].format("DD/MM/YYYY"));
      this.dataSumTripVehicle = getSumTripVehicle;
      this.paginatedDataTripVehicle = this.dataSourceTripVehicle
      this.paginatedDataTripDriver = this.dataSourceTripDriver
      this.dataSumTripVehicle.forEach((item , index)=>{
        const data = {
          index: index + 1,
          car_no_and_plate_no: item.car_no_and_plate_no,
          work: Number(item.work),
          day: Number(item.day),
        };
        this.dataSourceTripVehicle.push(data);
      });
      this.paginatedDataTripVehicle.total = this.dataSourceTripVehicle.length;
    },
    async fetchDataDriverDashboard(){
      this.dataSourceSumDriver = [];
      const getDriverDashboard = await UserService.getDriverDashboard();
      this.dataSumDriver = getDriverDashboard;
      this.dataSumDriver.forEach((driver, index) => {
        const data = {
          index: index + 1,
          firstname: driver.firstname,
          lastname: driver.lastname,
          phone_no: driver.phone_no,
          work_status_id: driver.work_status_id,
          work_status_name: driver.work_status_name,
          work_status_today: driver.work_status_today,
          username: driver.username,
          rating: driver.rating,
        };
        this.dataSourceSumDriver.push(data);
      });
      this.paginatedDataSumDriver = this.dataSourceSumDriver;
      this.paginatedDataSumDriver.total = this.dataSourceSumDriver.length;
    },
    async fetchDataVehicleDashboard(){
      const getVehicleDashboard = await UserService.getVehicleDashboard(this.dateRange[0].format("DD/MM/YYYY"),this.dateRange[1].format("DD/MM/YYYY"));
      this.dataSumVehicle = getVehicleDashboard;
      getVehicleDashboard.forEach((vehicle, index) => {
        const data = {
          index: index + 1,
          car_no_and_plate_no: vehicle.car_no_and_plate_no,
          fullname: vehicle.fullname,
          status_ready: vehicle.status_ready,
          booking_car_no: vehicle.booking_car_no,
          booking_status: vehicle.booking_status,
          booking_id: vehicle.booking_id,
          time_start_end: `${vehicle.time_start || vehicle.time_end 
              ? dayjs(vehicle.time_start).format("DD/MM/YYYY HH:mm") + " - " + dayjs(vehicle.time_end).format("DD/MM/YYYY HH:mm") 
              : "" 
          }`,
        };
        this.dataSourceSumVehicle.push(data);
      });
      setTimeout(() => {
      this.paginatedDataSumVehicle = this.dataSourceSumVehicle;
      }, 500);
      this.paginatedDataSumVehicle.total = this.dataSourceSumVehicle.length;
    },
  },
  async mounted() {
    this.connectSocket();
    await this.loadPage();
  },
  beforeDestroy() {
    this.chart_elements.forEach((el) => {
      if (el.chart) {
        el.chart.destroy(); // ทำลาย chart ทำลาย component
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.sticky {
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  border: 1px solid #e0e0e0;
  padding: 1rem;
}

.table-divider {
  border-top: 1px solid #f4f4f4;
}

.chart-content-table {
  margin: 2%;
  padding: 5px;
  border: 1px solid #f4f4f4;
}

table {
  width: 100%;
  table-layout: auto;
  text-align: center;
  border-collapse: collapse;
  border-radius: 5px;

  tr {
    border-bottom: 1px solid #e8e8e8;
    line-height: 3rem;
  }
}

.chart-content {
  padding: 10px;
}

.chart-container {
  text-align: center;
  margin: 10px;
  border: 1px solid #f4f4f4;
  border-radius: 5px;

  .chart-header {
    font-size: 16px;
    padding: 16px;
    background-color: #e5e5e5;
  }
}

.card-container {
  margin: 3%;
  display: flex;
  height: fit-content;
}

.dashboard-card {
  text-align: center;
  width: 100%;
  margin: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;

  .icon {
    font-size: 50px;
  }

  span {
    margin: 1rem;
    font-size: 14px;
    color: white;
  }
}

.dashboard-container {
  padding: 8px;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  // margin-top: 5px;
  // margin-bottom: 5px;
}

.red {
  background-color: red;
}

.green {
  background-color: rgb(0, 200, 0);
}
</style>