<template>
  <div style="padding: 15px; height: 100vh;">
    <a-card title="แชทพนักงานขับรถ">
      <a-row>
        <a-input
        v-model:value="driverName"
        placeholder="ค้นหาแชทพนักงานขับรถ"
        style="width: 40%;"
        @keyup.enter="findDriversByName"
        />
        <a-button type="primary" @click="findDriversByName">Send</a-button>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="6" style="overflow-y: auto; height: 100%;">
          <Chatlist :users="users" @userSelected="handleUserSelected" />
        </a-col>
        <a-col :span="18" style="height: 100%;">
          <ChatUser
            v-if="selectedUser"
            :targetUser="selectedUser"
            :socket="socketService"
          />
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import DriversChannelService from '../../api/DriversChannelService';
import Chatlist from '../../components/chat_list.vue';
import ChatUser from '../../components/chat_user.vue';
import socketService from '../../api/SocketService';

export default {
  name: 'Chat',
  components: {
    ChatUser,
    Chatlist,
  },
  data() {
    return {
      driverName: '',
      users: [],
      selectedUser: null,
      socketService,
    };
  },
  mounted() {
    this.connectToSocket();
    this.fetchAllDrivers();
  },
  beforeUnmount() {
    this.disconnectFromSocket();
  },
  watch:{
    driverName:{
      immediate: false,
      handler: 'findDriversByName',
    },
    users:{
      immediate: false,
      handler(newUsers) {
        if (this.selectedUser && !newUsers.find(user => user.id === this.selectedUser.id)) {
          this.selectedUser = null
        }
      }
    }
  },
  methods: {
    async findDriversByName() {
      try {
        if (!this.driverName.trim()) {
          await this.fetchAllDrivers();
        } else {
          const response = await DriversChannelService.getAllDriversChannelWithDriverByName(this.driverName);
          this.users = response;
        }
      } catch (error) {
        console.error('Error while searching for drivers:', error);
      }
    },
    async fetchAllDrivers() {
      try {
        const response = await DriversChannelService.getAllDriversChannelWithDriver();
        this.users = response
      } catch (error) {
        console.error('Error while fetching drivers:', error);
      }
    },
    handleUserSelected(user) {
      this.selectedUser = user;
    },
    connectToSocket() {
      this.socketService.connect();
    },
    disconnectFromSocket() {
      this.socketService.disconnect();
    },
  },
};
</script>
