<template>
  <div style="height: 75vh">
    <div v-if="filteredUsers.length === 0" class="no-users-found">
      No users found.
    </div>
    <div v-else>
      <div
        v-for="(user, index) in filteredUsers"
        :key="user.id"
        :class="['user-list-item', { 'selected': selectedUser && selectedUser.id === user.id }]"
        @click="selectUser(user)"
      >
        <!-- <a-badge :dot="true">
        </a-badge> -->
        <a-avatar :src="user.avatar"/>
        <div class="user-details">
          <div class="user-name">{{ user.firstname + " " + user.lastname }}</div>
          <div class="user-last-message">{{ user.lastMessage }}</div>
          <!-- <div v-if="!user.isRead" class="user-unread-indicator">Unread</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatList',
  props: {
    users: {
      type: Array,
      required: true,
    },
    searchQuery: {
      type: String,
      required: false,
      default: '', 
    },
  },
  data() {
    return {
      selectedUser: null,
    };
  },
  computed: {
    filteredUsers() {
      return [...this.users]
    },
  },
  methods: {
    selectUser(user) {
      this.$emit('userSelected', user);
      this.selectedUser = user;
    },
  },
};
</script>

<style scoped>
.user-list-item {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.user-list-item:hover {
  background-color: #f0f0f0;
}

.user-list-item.selected {
  background-color: #d3d3d3;
}

.user-avatar {
  margin-right: 10px;
}

.user-details {
  flex: 1;
}

.user-name {
  font-weight: bold;
}

.user-last-message {
  font-size: 12px;
  color: #888;
}

.user-unread-indicator {
  color: red;
  font-size: 12px;
  font-weight: bold;
}
</style>
