<template>
    <div style="padding: 15px;height: 100vh">
        <div v-if="render_type == 'new_user' || render_type == 'edit_user' " class="app-content">
            <a-card :title="`ผู้ใช้งาน : ${is_render_update? info_data.username || initialInfoData.username :'สมัครผู้ใช้งานใหม่'} - อัพเดตล่าสุด ${render_date(is_render_update? info_data.updated_at : null)}`">
                <template #extra>
                    <a-button style="display: flex" @click="$router.go(-1)" size="large">
                        <template #icon>
                            <span class="material-symbols-outlined">arrow_back_ios</span>
                        </template>
                        ย้อนกลับ
                    </a-button>
                </template>

                <a-tabs :active-tab-key="user_tap_key">
                    <a-tab-pane key="personal" tab="ข้อมูลส่วตัวผู้ใช้งาน">
                        <a-row type="flex">
                            <a-col :span="is_render_update? 24 : 24" v-if="info_data.role != 'Admin'">
                                <a-card :title="`${is_render_update?'แก้ไขผู้ใช้งาน':'เพิ่มผู้ใช้งาน'}`" style="width: 98%">
                                    <div style="display: flex" v-if="!is_render_update">
										<a-select
											placeholder="โปรดเลือกประเภทผู้ใช้งาน"
											v-model:value="info_data.role"
											:disabled="is_render_update"
											style="width: 20%"
										>
											<a-select-option
												v-for="({ text, value }, i) in roles"
												:key="i"
												:value="value"
												>{{ text }}
											</a-select-option>
										</a-select>
									</div>
                                    <!-- แก้ไข -->
									<div v-if="is_render_update">
                                        
										<a-spin v-if="isLoading" tip="Loading...">Loading...</a-spin>

										<div v-else>
											<CreateEditComponent 
												:create="false"
												:model="initialInfoData.modelname"
												:model_data="initialInfoData" 
												@apply_model_data="onModelDataApplied"
												@close="onClose"
											/>
										</div>
									</div>
                                    <div v-if="info_data.role === 'sale'">
										<div style="margin-top: 25px;">
											<CreateEditComponent 
												:create="true"
												model="Sale"
												:model_data="{}" 
												@apply_model_data="onModelDataApplied"
												@close="onClose"
											/>
										</div>
									</div>
                                    <div v-if="info_data.role === 'tm'">
										<div style="margin-top: 25px;">
											<CreateEditComponent 
												:create="true"
												model="TM"
												:model_data="{}" 
												@apply_model_data="onModelDataApplied"
												@close="onClose"
											/>
										</div>
									</div>
									<div v-if="info_data.role === 'tsm'">
										<div style="margin-top: 25px;">
											<CreateEditComponent 
												:create="true"
												model="TSM"
												:model_data="{}" 
												@apply_model_data="onModelDataApplied"
												@close="onClose"
											/>
										</div>
									</div>
                                </a-card>
                            </a-col>
                        </a-row>
                    </a-tab-pane>

                    <a-tab-pane v-if="info_data.role != 'Admin'" key="permission" tab="สิทธิการใช้งานเมนู" :disabled='!is_render_update || !editable'>
                        <a-card title="สิทธิการใช้งานเมนู">
                            <a-table 
                                :columns="permission_columns" 
                                :data-source="menu_permissions" 
                                bordered size="small"
                                :pagination="false">
                                <template #bodyCell="{ column, record }">
                                    <template v-if="column.key == 'enable'" >
                                        <a-switch v-model:checked="record.enable" @change="onEnableSwitchChange(record.enable, record)"/>
                                    </template>
                                    <template v-if="column.key == 'editable'" >
                                        <a-switch v-model:checked="record.editable" :disabled='!record.enable'/>
                                    </template>
                                </template>
                            </a-table>
                            <a-row justify="end">
                                <a-col>
                                    <a-button type="primary" html-type="submit" size="large"
                                        style="margin: 4px;text-align: right"
                                        @click="onPermissionSave">
                                        <span class="material-symbols-outlined" style="font-size: 16px;margin-right: 4px">Edit</span>
                                                        บันทึกข้อมูล
                                    </a-button>
                                </a-col>
                            </a-row>
                        </a-card>
                    </a-tab-pane>
                    
                    <a-tab-pane v-if="info_data.role != 'Admin'" key="access" tab="การเข้าถึงบริษัท" :disabled='!is_render_update || !editable'>
                        <a-card title="การเข้าถึงบริษัท">
                            <a-form  
                                layout="vertical"
                                :model="access" name="access-form" 
                                @finish="onSaveCompanyAccess"
                                autocomplete="off">
                                <a-form-item label="บริษัทที่เข้าถึงได้" name="access_companies"
                                :rules="[{ required: true, message: 'เลือกบริษัทที่เข้าถึงได้' }]">
                                    <a-select
                                        v-model:value="access.access_companies" 
                                        mode="multiple"
                                        placeholder="บริษัทที่เข้าถึงได้">
                                        <a-select-option v-for="{ id, fullname }, i in car_companies" :key="i" :value="id">
                                            {{fullname}}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>

                                <a-row justify="end">
                                    <a-col>
                                        <a-button type="primary" html-type="submit" size="large"
                                            style="margin: 4px;text-align: right">
                                                <span class="material-symbols-outlined" style="font-size: 16px;margin-right: 4px">Edit</span>
                                                บันทึกข้อมูล
                                        </a-button>
                                    </a-col>
                                </a-row>
                            </a-form>
                        </a-card>
                    </a-tab-pane>
                </a-tabs>

            </a-card>
        </div>

        <div v-else class="app-content">
            <a-card :title="title">
                <template #extra>
                    <a-space>
                        <router-link :to="`/app/manageUsers/new_user`" v-if="editable">
                            <a-button v-if="button_title" style="display: flex"
                                type="primary" size="large" @click="button_on">
                                <template #icon><span class="material-symbols-outlined">Add</span></template>
                                {{ button_title }}
                            </a-button>
                        </router-link>

                        <a-dropdown v-if="allow_import">
                            <template #overlay>
                                <a-menu >
                                    <a-menu-item key="1">
                                        <span class="material-symbols-outlined">description</span>
                                        import
                                    </a-menu-item>
                                    <a-menu-item key="2">
                                        <span class="material-symbols-outlined">description</span>
                                        Export
                                    </a-menu-item>
                                </a-menu>
                            </template>
                            <a-button style="display: flex;">
                                <span class="material-symbols-outlined">Settings</span>
                                ตัวเลือก
                                <span class="material-symbols-outlined">expand_more</span>
                            </a-button>
                        </a-dropdown>
                    </a-space>
                </template>

                <a-row>
                    <a-space>
                        <a-input-search v-if="input" v-model:value="search_input"
                            :placeholder="`ค้นหา ${input}`" enter-button="ค้นหา" />
                        <a-select v-if="select_type" style="width: 17rem" v-model:value="selected_type">
                            <a-select-option :value="null">ทั้งหมด</a-select-option>                  
                            <a-select-option v-for="{ text, value }, i in [
                                { text: 'ผู้ดูแลระบบ', value: 'ADMIN' },
                                // { text: 'คนขับ', value: 'DRIVER' },
                                // { text: 'ลูกค้า', value: 'CUSCOM' },
                                { text: 'พนักงานขาย', value: 'SALE' },
                                { text: 'ฝ่ายจัดการรถเช่า', value: 'TM' },
                                { text: 'ฝ่ายซ่อมบำรุงและอุบัติเหตุ', value: 'TSM' },
                            ]" :key="i" :value="value">{{ text }}
                            </a-select-option>
                        </a-select>
                        <a-button v-if="clear" @click="clear_search" type="dashed">CLEAR</a-button>
                    </a-space>

                </a-row>
                <br>
                <a-row>
                    <a-table :columns="columns.filter((c)=> !editable ? !c.key.includes('id') : true)" 
                        :data-source="filtered_list(show_table_list)" bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }"
                        :pagination="{ total: pagination.total, current: pagination.page, pageSize: pagination.perPage }"
                        @change="handleTableChange"
                    >
                        <template #bodyCell="{ column, record }">
                            <template v-if="column.key === 'fullname'">
                                {{record.firstname}} {{record.lastname}}
                            </template>
                               <template v-if="column.key === 'role'">
                                {{
									record.role == "sale" ? "SALE" : record.role.toUpperCase()
								}}
                                <!-- {{record.role == 'admin' 
                                    ? 'ADMIN' 
                                    : record.role == 'sale' 
                                                    ? 'SALE' 
                                                    : (record.role.slice(0, record.role.length - 1)).toUpperCase()
                                }} -->
                            </template>
                            <template v-if="column.key === 'id'" >
                                <div>
                                    <a @click="edit_user(record.id, record)"><span style="font-size: 18px" class="material-symbols-outlined">drive_file_rename_outline</span></a>
                                    <a-divider type="vertical"></a-divider>
                                    <a @click="remove_user(record.id, record)"><span style="font-size: 18px" class="material-symbols-outlined">delete</span></a>
                                </div>
                            </template>
                            <template v-else-if="column.key == 'is_active'" >
                                <a-switch v-model:checked="record.is_active"  :disabled="!editable" @change="(event) => update_user_is_active(event, record.role.toLowerCase(), record.id )"/>

                            </template>
                        </template>

                    </a-table>
                </a-row>
            </a-card>
        </div>
    </div>
</template>

<script>
import UserService from '../../api/UserService'
import SystemService from '../../api/SystemService'
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
dayjs.extend(buddhistEra);
import Swal from 'sweetalert2'
import Utility from '../../utility'
import th from 'dayjs/locale/th';
import { message } from 'ant-design-vue';
import CreateEditComponent from "@/components/CreateEditComponent.vue";
import CompanyService from '../../api/CompanyService'
import SaleService from '../../api/SaleService';
import TMService from '../../api/TMService';
import TSMService from '../../api/TSMService';
export default {
    name: 'manage-users',
    components: {
		CreateEditComponent,
	},
    computed: {
        render_type() {
            return this.$route.name
        },
        is_render_update(){
            this.init_data()
            return this.$route.name == 'edit_user'
        }
    },
    data() {
        return {
            editable: Utility.get_permission_editable_with_key('manage_users'),
            initialInfoData: [],
			initialId: null,
			isLoading: true, // Initially set to true
			fileList: [],
            show_table_list: [],
            isLoading: true,
            pagination: {
                page: 1,
                perPage: 10,
            },
            title: 'จัดการผู้ใช้งาน',
            button_title: 'เพิ่มผู้ใช้งาน',
            search_input: null,
            input: 'รหัส / ชื่อผู้ใช้งาน',
            selected_type: null,
            select_type: true,
            allow_import: false,
            clear: true,
            lower_buttons: [
                { title: 'เพิ่มผู้ใช้งาน', name: 'add_user' },
                // { title: 'เพิ่มผู้ใช้งานจากไฟล์', name: 'add_user_from_file' },
            ],
            columns: [
                {
                    title: 'Username',
                    dataIndex: 'username',
                    key: 'username',
                    sorter: true,
                    width: 200
                },
                 {
                    title: 'ชื่อ-นามสกุล',
                    dataIndex: 'fullname',
                    key: 'fullname',
                    sorter: true,
                    width: 200
                }, {
                    title: 'เบอร์โทรศัพท์',
                    dataIndex: 'phone_no',
                    key: 'phoneNo',
                    width: 125
                }, {
                    title: 'Email',
                    dataIndex: 'email',
                    key: 'email',
                    width: 120
                }, {
                    title: 'สิทธิ์',
                    dataIndex: 'role',
                    key: 'role',
                    width: 120
                }, {
                    title: 'สถานะ',
                    dataIndex: 'is_active',
                    key: 'is_active',
                    width: 120
                }, {
                    title: 'ตัวเลือก',
                    dataIndex: 'id',
                    key: 'id',
                    width: 100,
                    fixed: 'right'
                }
            ],
            user_tap_key: 'personal',
            roles: [],
            info_data: {
                id: null,
                username: null,
                password: null,
                confirm_password: null,
                role: null,
                firstname: null,
                lastname: null,
                email: null,
                phone_no: null,
                is_active: true,
                permission: null
            },
            password_data: {
                password: null,
                confirm_password: null,
            },
            permission_columns: [
                {
                    title: 'เมนู',
                    dataIndex: 'menu',
                    key: 'menu',
                },
                   {
                    title: 'ใช้งาน',
                    dataIndex: 'enable',
                    key: 'enable',
                    width: 120,
                    fixed: 'right'
                },
                   {
                    title: 'แก้ไข',
                    dataIndex: 'editable',
                    key: 'editable',
                    width: 120,
                    fixed: 'right'
                },
            ],
            menu_permissions: [
				{
					key: "dashboard",
					menu: "แดชบอร์ด",
					enable: false,
					editable: false,
				},
				{
					key: "calendar",
					menu: "ตารางงาน",
					enable: false,
					editable: false,
				},
				{
					key: "schedule",
					menu: "ตารางรถ",
					enable: false,
					editable: false,
				},
				{
					key: "manage_tour",
					menu: "จัดการลูกค้าและกรุ๊ปทัวร์",
					enable: false,
					editable: false,
				},
				{
					key: "quotation",
					menu: "ใบเสนอราคา",
					enable: false,
					editable: false,
				},
				{
					key: "booking",
					menu: "การจองจัดรถ",
					enable: false,
					editable: false,
				},
				{
					key: "billing",
					menu: "การวางบิล",
					enable: false,
					editable: false,
				},
				{
					key: "company",
					menu: "จัดการข้อมูลที่เกี่ยวกับยานพาหนะ",
					enable: false,
					editable: false,
				},
				// {
				// 	key: "manage_sales",
				// 	menu: "จัดการงานขาย",
				// 	enable: false,
				// 	editable: false,
				// },
				{
					key: "manage_car_parts",
					menu: "จัดการอะไหล่",
					enable: false,
					editable: false,
				},
				{
					key: "manage_repair_main",
					menu: "รายการแจ้งซ่อม",
					enable: false,
					editable: false,
				},
				{
					key: "manage_repair",
					menu: "แจ้งซ่อม/อุบัติเหตุ",
					enable: false,
					editable: false,
				},
				{
					key: "gas_station",
					menu: "ปั๊มน้ำมัน",
					enable: false,
					editable: false,
				},
				{
					key: "roll_call",
					menu: "ตรวจเช็ครถ (Roll Call)",
					enable: false,
					editable: false,
				},
				{
					key: "manage_users",
					menu: "จัดการผู้ใช้งาน",
					enable: false,
					editable: false,
				},
                {
					key: "cr_chat",
					menu: "แชทพนักงานขับรถ",
					enable: false,
					editable: false,
				},				
			],
            car_companies: [
            ],
            access: {
                access_companies: []
            },
            delayed: null,
        }
    },
    methods: {
        async validate_email(_rule, value) {
            if (value) {
                clearTimeout(this.delayed);
            }

            if (this.is_render_update) {
                return true
            } else {
                return new Promise((resolve, reject) => {
                    this.delayed = setTimeout(() => {
                        SystemService.check_duplicate(
                            'Sale',
                            `field=email&value=${value}&id=${this.action_type}`
                        ).then((res) => {
                            if (res) {
                                reject('มีข้อมูลนี้อยู่แล้ว');
                            } else {
                                resolve();
                            }
                        });
                    }, 500);
                });
            }
        },
        async validate_duplicate(_rule, value) {
            if(_rule.required && !value) {
                reject('โปรดระบุ');
            }
            if (this.is_render_update) {
                return new Promise((resolve, reject) => {
                     setTimeout(() => {
                        SystemService.check_duplicate_username(
                            `${this.info_data.role}`,
                            `field=username&value=${value}&id=${this.info_data.id}`
                        ).then((res) => {
                            if (res) {
                                reject('มีข้อมูลนี้อยู่แล้ว');
                            } else {
                                resolve();
                            }
                        });
                    }, 500);
                });
            } else {
                return new Promise((resolve, reject) => {
                    this.delayed = setTimeout(() => {
                        SystemService.check_duplicate(
                            `${this.info_data.role}`,
                            `field=username&value=${value}&id=${this.this.info_data.id}`
                        ).then((res) => {
                            if (res) {
                                reject('มีข้อมูลนี้อยู่แล้ว');
                            } else {
                                resolve();
                            }
                        });
                    }, 500);
                });
            }
        },
        render_date(date){
            return dayjs(date || dayjs()).format('DD-MM-YYYY')
        },
        init_data(){
            if(this.$route.name == 'edit_user'){
                this.get_user_with_username()
                this.get_id_detail();
                this.roles = [
                    {
                        value: "admin", 
                        text:"ผู้ดูแลระบบ"
                    },{
                        value: "employee", 
                        text:"ลูกจ้าง"
                    },{
                        value: "sale", 
                        text:"พนักงานขาย"
                    },{
                        value: "driver", 
                        text:"คนขับ"
                    },{
                        value: "cuscom", 
                        text:"ลูกค้า"
                    }
            ]
            }else{
                this.roles = [
                    {
						value: "sale",
						text: "พนักงานขาย",
					}, {
						value: "tm",
						text: "ฝ่ายจัดการรถเช่า",
						
					}, {
						value: "tsm",
						text: "ฝ่ายซ่อมบำรุงและอุบัติเหตุ",
					},
                ]
            }
        },
        handleTableChange(pagination, filters, sorter) {
			// console.log({pagination, filters, sorter});
			this.pagination.page = pagination.current;
            this.pagination.perPage = pagination.pageSize;
			this.onRefresh();
		},
        async init_users() {
            const data = await CompanyService.getManageUsers(this.pagination.page, this.pagination.perPage)
            this.pagination = data
            this.show_table_list = data.data
        },
        async get_id_detail() {
			if (this.$route.params.user) {
				this.isLoading = true;
                if (this.$route.params.role == 'sale') {
                    const res = await SaleService.getFromIdWithRelationById(this.initialId ? this.initialId : this.initialId = localStorage.getItem('initialId'));
                    this.initialInfoData = res.data;
                    if (this.initialInfoData.permission) {
                        this.menu_permissions.map((perm) => {
                            ///0 f f, 1 t f, 2 t t
                            var { permission } = this.initialInfoData
                            perm.enable = permission[perm.key] == 1 || permission[perm.key] == 2 ? true : false
                            perm.editable = permission[perm.key] == 2 ? true : false
                            return perm
                        })
                        if (this.initialInfoData.permission.car_company_ids) {
                            this.access.access_companies = this.initialInfoData.permission.car_company_ids.split(',').map(e => parseInt(e));
                        }
                    }
                } else if (this.$route.params.role == 'tms') {
                    const res = await TMService.getFromIdWithRelationById(this.initialId ? this.initialId : this.initialId = localStorage.getItem('initialId'));
                    this.initialInfoData = res.data;
                    if (this.initialInfoData.permission) {
                        this.menu_permissions.map((perm) => {
                            ///0 f f, 1 t f, 2 t t
                            var { permission } = this.initialInfoData
                            perm.enable = permission[perm.key] == 1 || permission[perm.key] == 2 ? true : false
                            perm.editable = permission[perm.key] == 2 ? true : false
                            return perm
                        })
                        if (this.initialInfoData.permission.car_company_ids) {
                            this.access.access_companies = this.initialInfoData.permission.car_company_ids.split(',').map(e => parseInt(e));
                        }
                    }
                } else if (this.$route.params.role == 'tsms') {
                    const res = await TSMService.getFromIdWithRelationById(this.initialId ? this.initialId : this.initialId = localStorage.getItem('initialId'));
                    this.initialInfoData = res.data;
                    if (this.initialInfoData.permission) {
                        this.menu_permissions.map((perm) => {
                            ///0 f f, 1 t f, 2 t t
                            var { permission } = this.initialInfoData
                            perm.enable = permission[perm.key] == 1 || permission[perm.key] == 2 ? true : false
                            perm.editable = permission[perm.key] == 2 ? true : false
                            return perm
                        })
                        if (this.initialInfoData.permission.car_company_ids) {
                            this.access.access_companies = this.initialInfoData.permission.car_company_ids.split(',').map(e => parseInt(e));
                        }
                    }
                }
				localStorage.setItem("initialInfoData", JSON.stringify(this.initialInfoData));
				localStorage.setItem("initialId", this.initialId);
			}		
			this.isLoading = false;
		},
        async get_user_with_username() {
            if(this.$route.params.user){
                console.log(this.$route.params.role, this.$route.params.user);
                 this.info_data = await UserService.get_user_detail(this.$route.params.role, `username=${this.$route.params.user}`)
                 if(this.info_data.permission){
                    this.menu_permissions.map((perm)=>{
                        ///0 f f, 1 t f, 2 t t
                        var {permission} = this.info_data
                        perm.enable = permission[perm.key] == 1 || permission[perm.key] == 2? true : false
                        perm.editable = permission[perm.key] == 2 ? true : false
                        return perm
                    })
                    if (this.info_data.permission.car_company_ids) {
                        this.access.access_companies = this.info_data.permission.car_company_ids.split(',').map(e => parseInt(e));
                    }
                 }
            }
        },
        async get_car_companies(){
            this.car_companies = await SystemService.get_all('CarCompany')
        },
        clear_search() {
            this.search_input = null
            this.selected_type = null
        },
        filtered_list(array) {
            return array.filter(item => {
                if (this.selected_type) {
                    return item.role == this.selected_type.toLowerCase()
                } else {
                    return true
                }
            }).filter(({ id, firstname, lastname, username }) => {
                if(this.search_input && firstname && lastname){
                    return firstname.toLowerCase().includes(this.search_input.toLowerCase()) ||
                        lastname.toLowerCase().includes(this.search_input.toLowerCase()) || 
                        id.toString().includes(this.search_input.toLowerCase()) ||
                        username.toLowerCase().includes(this.search_input.toLowerCase())
                } else {
                    return true
                }
            })
        },
        button_on(){
            this.onResetData()
        },
        async onCheckUsername(){
             var v = this.show_table_list.map(({username}) => username).includes(this.info_data.username)
             return this.is_render_update? Promise.resolve(true) : !v ? Promise.resolve(true): Promise.reject(v)
        },
        async onCheckPasswordMatch(){
            var v = this.info_data.password === this.info_data.confirm_password
            return v ? Promise.resolve(true): Promise.reject(v)
        },
        async onCheckChangePasswordMatch(){
            var v = this.password_data.password === this.password_data.confirm_password
            return v ? Promise.resolve(true): Promise.reject(v)
        },
        async update_user_is_active(event, role, id) {
            const id_update = this.info_data?.id || id
            const role_update = this.info_data?.role || role
            if(event){
                if (role == 'sale') {
                    await SaleService.updateSaleIsActiveById({ is_active: event }, id_update)
                    .then(() => {
                        message.success({ content: 'เปิดใช้พนักงานขายสำเร็จ', key: 'update_driver_is_active', duration: 1.5 });
                    })
                } else if (role == 'tms') {
                    await TMService.updateTMIsActiveById({ is_active: event }, id_update)
                    .then(() => {
                        message.success({ content: 'เปิดใช้ฝ่ายจัดการรถเช่า', key: 'update_driver_is_active', duration: 1.5 });
                    })
                } else if (role == 'tsms') {
                    await TSMService.updateTSMIsActiveById({ is_active: event }, id_update)
                    .then(() => {
                        message.success({ content: 'เปิดใช้ฝ่ายซ่อมบำรุงและอุบัติเหตุ', key: 'update_driver_is_active', duration: 1.5 });
                    })
                }
                
            } else if (event == false) {
                if (role == 'sale') {
                    await SaleService.updateSaleIsActiveById({ is_active: event }, id_update)
                    .then(() => {
                        message.warning({ content: 'ปิดใช้พนักงานขายสำเร็จ', key: 'update_driver_is_active', duration: 1.5 });
                    })
                } else if (role == 'tms') {
                    await TMService.updateTMIsActiveById({ is_active: event }, id_update)
                    .then(() => {
                        message.warning({ content: 'ปิดใช้ฝ่ายจัดการรถเช่า', key: 'update_driver_is_active', duration: 1.5 });
                    })
                } else if (role == 'tsms') {
                    await TSMService.updateTSMIsActiveById({ is_active: event }, id_update)
                    .then(() => {
                        message.warning({ content: 'ปิดใช้ฝ่ายซ่อมบำรุงและอุบัติเหตุ', key: 'update_driver_is_active', duration: 1.5 });
                    })
                }
            }
            this.load_page()
        },
        async onManageUser(value){
            if(this.is_render_update){
                await UserService.update_user(this.info_data.id, this.$route.params.role, 'update', value)
                Swal.fire(
                            'แก้ไขผู้ใช้สำเร็จ!',
                            'แก้ไขผู้ใช้สำเร็จแล้ว',
                            'success'
                )
                await this.onRefresh()
                this.$router.go(-1)
            }else{
                const role = value.role
                delete value.role
                delete value.id
                delete value.confirm_password
                await UserService.create_user(role, {data: value})
                this.onRefresh()
                Swal.fire(
                            'เพิ่มผู้ใช้สำเร็จ!',
                            'เพิ่มผู้ใช้สำเร็จแล้ว',
                            'success'
                )
                this.onResetData()
                this.$router.go(-1)
            }
        },
        async onChangePassword(value){
          await UserService.update_user(this.info_data.id, this.$route.params.role, 'change_password', {password: value.password})
                Swal.fire(
                            'แก้ไขรหัสผ่านผู้ใช้สำเร็จ!',
                            'แก้ไขรหัสผ่านผู้ใช้สำเร็จแล้ว',
                            'success'
                )
                this.password_data = {
                    password: null,
                    confirm_password: null,
                }
        },
        onEnableSwitchChange(v, permission){
            if(!v){
                permission.editable = false
            }
        },
        async onPermissionSave(){            
            var permission = this.menu_permissions.reduce(function(map, obj) {
                    map[obj.key] = !obj.enable ? 0 : obj.editable ? 2 : 1;
                    return map;
            }, {});
            console.log("this.$route.params.role", this.$route.params.role);
            if (this.$route.params.role == 'sale') {
                await SaleService.updateSalePermissionById(permission, this.info_data.id)
            } else if (this.$route.params.role == 'tms') {
                await TMService.updateTMPermissionById(permission, this.info_data.id ? this.info_data.id : this.initialId)
            } else if (this.$route.params.role == 'tsms') {
                await TSMService.updateTSMPermissionById(permission, this.info_data.id ? this.info_data.id : this.initialId)
            } else {
                await UserService.update_user(this.info_data.id, this.$route.params.role, 'permission', permission)
            }
            
            Swal.fire(
                'แก้ไขผู้ใช้สำเร็จ!',
                'แก้ไขผู้ใช้สำเร็จแล้ว',
                'success'
            )
            this.$router.go(-1)
        },
        async onSaveCompanyAccess() {
            await UserService.update_user(
                this.info_data.id, 
                this.$route.params.role, 
                'access_company', 
                {car_company_ids: this.access.access_companies}
            )
            Swal.fire(
                'แก้ไขผู้ใช้สำเร็จ!',
                'แก้ไขผู้ใช้สำเร็จแล้ว',
                'success'
            )
            this.$router.go(-1)
        },
        async onRefresh(){
            this.$message.loading({ content: 'กำลังโหลดข้อมูลผู้ใช้...', key: 'init1', duration: 0 })
            await this.init_users()
            this.$message.success({ content: 'โหลดข้อมูลผู้ใช้เสร็จสิ้น...', key: 'init1', duration: 0.5 })
        },
        onResetData(){
          this.info_data = {
                    username: null,
                    password: null,
                    confirm_password: null,
                    role: null,
                    firstname: null,
                    lastname: null,
                    email: null,
                    phone_no: null,
                    is_active: true,
            }
        },
        edit_user(id, user){
            this.initialId = id;
            this.$router.push(`/app/manageUsers/${user.role}/`+user.username)
        },
       async remove_user(id, user){
            Swal.fire({
                title: 'ลบผู้ใช้งาน?',
                text: `คุณต้องการลบผู้ใช้งาน ${user.username} นี้หรือไม่`,
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก'
                }).then(async (result) => {
                if (result.isConfirmed) {
                    if (user.role == 'sale') {
                        await SaleService.deleteSale(id)
                    } else if (user.role == 'tms') {
                        await TMService.deleteTM(id)
                    } else if (user.role == 'tsms') {
                        await TSMService.deleteTSM(id)
                    }
                    this.onRefresh()
                }
            })
        },
        async load_page() {
            this.onRefresh()
            this.get_id_detail();

            this.$message.loading({ content: 'กำลังโหลดข้อมูลตำแหน่ง...', key: 'init2', duration: 0 })
            await this.get_user_with_username()
            this.$message.success({ content: 'โหลดข้อมูลผู้ใช้เสร็จสิ้น...', key: 'init2', duration: 0.5 })

            this.$message.loading({ content: 'กำลังโหลดข้อมูลบริษัท...', key: 'init3', duration: 0 })
            await this.get_car_companies()
            this.$message.success({ content: 'เสร็จสิ้นการโหลดทั้งหมด!', key: 'init3', duration: 1 })
        }
    },
    async mounted() {
        this.load_page()

        // check user permission
        var my_user = localStorage.getItem('user_profile')? JSON.parse(localStorage.getItem('user_profile')):{};
        var my_role = localStorage.getItem('role')? localStorage.getItem('role'):'';
        if(!this.editable){
            this.$router.push(`/app/manageUsers/${my_role}/`+my_user.username)
        }
    }
}
</script>